import React from "react";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { useEffect } from "react";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CompletedPostCard from "../../HomeComponents/CompletedPostCard/CompletedPostCard";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import useTodayPost from "../../../hooks/useTodayPost"

const TodayCompletedPosts = ({ openModal, setModalContentId }) => {
  const { auth } = useAuth();
  const {posts, setPosts, postLoading, setPostLoading} = useTodayPost();
  const [loading, setLoading] = useState(false);

  const fetchPosts = () => {
    setPostLoading(true);
    axios
      .get("/api/v1/complete/today", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setPosts(response.data.data)
        setPostLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPostLoading(false);
      });
  };

  useEffect(()=>{
    if(posts.length < 1){
      fetchPosts();
    }
  }, []);
  
  return (
    <div className="relative w-full min-h-[calc(100vh-220px)]">
      <TransitionGroup>
      {
        postLoading ? 
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <LoadingSpinner loading={true}/> 
        </div>
        : 
        posts.length > 0 ? 
        <CSSTransition timeout={500} classNames="post">
        <div className="grid grid-cols-1 lg:grid-cols-2 p-5 ">
        {
        posts.map((post) => (
            <CompletedPostCard
              key={post.id}
              content_id={post.content_id}
              content_type={post.content_type}
              ip_address={post.ip_address}
              finger_print={post.finger_print}
              completed_at={post.completed_at}
              on_use_at={post.on_use_at}
              ready_at={post.ready_at}
              openModal={openModal}
              setModalContentId={setModalContentId}
            />
        ))}
      </div>
      </CSSTransition>
      :
      <CSSTransition classNames="post" timeout={300}>
      <p className="text-center text-xl text-second mt-1">There is no completed post today</p>
      </CSSTransition>
      }
      </TransitionGroup>
          
    </div>
  );
};

export default TodayCompletedPosts;
