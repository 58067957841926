import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import useAccount from "../../../hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { BiLogoTiktok, BiLogoYoutube } from "react-icons/bi";
import HomeAccountPlaceHolder from "../HomeAccountPlaceholder/HomeAccountPlaceHolder";

const HomeAccountCards = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState();
  const { ytAccounts, tiktokAccounts, setYtAccounts, setTiktokAccounts } =
    useAccount();
  const navigate = useNavigate();

  const getAllYtAccounts = () => {
    setLoading(true);
    axios
      .get("api/v1/account/youtube", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setYtAccounts(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    ytAccounts?.length < 1 && getAllYtAccounts();
  }, []);

  const getAllTiktokAccounts = () => {
    setLoading(true);
    axios
      .get("api/v1/account/tiktok", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setTiktokAccounts(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    tiktokAccounts?.length < 1 && getAllTiktokAccounts();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 px-5 mt-8">
      {loading ? (
        <>
          <HomeAccountPlaceHolder />
          <HomeAccountPlaceHolder />
        </>
      ) : (
        ytAccounts.slice(0, 2).map((account) => (
          <div
            onClick={() => navigate("/accounts/youtube")}
            key={account.id}
            className="bg-main text-sm sm:text-base flex justify-between items-center rounded p-5 cursor-pointer hover:scale-105 transition "
          >
            {account.title}
            <BiLogoYoutube
              className=" w-[2.5em] h-[2.5em] bg-[#FF0000] rounded p-2"
              color="white"
            />
          </div>
        ))
      )}
      {loading ? (
        <>
          <HomeAccountPlaceHolder />
          <HomeAccountPlaceHolder />
        </>
      ) : (
        tiktokAccounts.slice(0, 2).map((account) => (
          <div
            onClick={() => navigate("/accounts/tiktok")}
            key={account.id}
            className="bg-main text-sm sm:text-base flex justify-between items-center rounded p-5 cursor-pointer hover:scale-105 transition "
          >
            {account.title}
            <BiLogoTiktok
              className="w-[2.5em] h-[2.5em] bg-black rounded p-2"
              color="white"
            />
          </div>
        ))
      )}
    </div>
  );
};

export default HomeAccountCards;
