import React, { useState } from 'react';
import { BiLogoYoutube, BiLogoTiktok } from 'react-icons/bi';
import AccountInfoModal from '../AccountInfoModal/AccountInfoModal';

const AccountCard = ({setIsInfoChanged, id, title, email, password, type, sessionId}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <div
      onClick={() => {
        setIsOpen(true);
      }}
      className={`hover:scale-105 transition cursor-pointer bg-main p-4 md:p-7 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`}
    >
      <div className="flex justify-between">
        <div className="text-base sm:text-lg flex flex-col justify-center">
          <h2 className="">{title}</h2>
        </div>
        <div className={`${type === "YOUTUBE" ? 'bg-[#FF0000]' : 'bg-black' } px-1 py-1 lg:py-7 lg:px-5 rounded`}>
            {
                type === "YOUTUBE" ?  <BiLogoYoutube color='white' className="w-[2rem] h-[2rem]" /> 
                :
                <BiLogoTiktok color='white' className='w-[2rem] h-[2rem]' />            
            }
        </div>
      </div>
    </div>
    <AccountInfoModal setIsChanged={setIsInfoChanged} sessionId={sessionId} id={id} title={title} isOpen={isOpen} setIsOpen={setIsOpen} email={email} password={password} type={type} />
    </>
  )
}

export default AccountCard
