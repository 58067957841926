import React from 'react'
import { BiLogoYoutube } from 'react-icons/bi'

const HomeAccountPlaceHolder = () => {
  return (
    <div className='bg-main text-sm sm:text-base flex justify-between items-center rounded p-5 cursor-pointer hover:scale-105 transition '>
     <div className='bg-[#515559] text-[#515559] w-[8em] h-[1em] animate-blink'></div> 
    <div className=' w-[2.5em] h-[2.5em] bg-[#515559] animate-blink rounded p-2 text-[#515559'>
        .
    </div>
    </div>
  )
}

export default HomeAccountPlaceHolder
