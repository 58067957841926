import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios';
import useConfig from '../../../hooks/useConfig';
import LoadingSpinner from '../../../components/CommonComponents/LoadingSpinner/LoadingSpinner';
import ConfigParamCard from '../../../components/ConfigComponents/ConfigParamCard/ConfigParamCard';
import AddParamCard from '../../../components/ConfigComponents/AddParamCard/AddParamCard';

const ConfigParam = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const maintenance = false;
  const { allConfigParams, setAllConfigParams, paramsArray, setParamsArray, keysArray, setKeysArray, dataTypes, setDataTypes, contentTypes, setContentTypes } = useConfig();

  const getAllParams = () => {
    setLoading(true);
    axios
      .get("api/v1/config/param?index=0&limit=20", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setAllConfigParams(response.data.data);
        getParamsArray(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getParamsArray = (params) => {
    const uniqueParams = new Set();
    const updatedKeysArray = new Set();
    params.forEach((param) => {
      uniqueParams.add(param.data_type);
    });

    params.forEach((param)=>{
      updatedKeysArray.add(param.key);
    })
    setParamsArray([...uniqueParams]);
    setKeysArray(updatedKeysArray);
  };

  const getAllDataTypes = () => {
    setLoading(true);
    axios
    .get("api/v1/config-data-type", {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    })
    .then((response) => {
      setLoading(false);
      setDataTypes(response.data.data);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
  }

  const getAllContentType = () => {
    axios.get(('/api/v1/content-type'), {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    })
    .then((response)=> {
      setLoading(false)
      setContentTypes(response.data.data);
    })
    .catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    contentTypes.length < 1 && getAllContentType();
  } ,[])
  
  useEffect(() => {
    allConfigParams.length < 1 && getAllParams();
  }, []);

  useEffect(() => {
    isChanged && getAllParams();
  }, [isChanged]);

  useEffect(() => {
    isAdded && getAllParams();
  }, [isAdded]);

  useEffect(() => {
    dataTypes.length < 1 && getAllDataTypes();
  }, []);

  return (
    <div className="w-full h-full p-5">
    {loading ? (
      <div className="h-screen">
        <LoadingSpinner loading={true} />
      </div>
    ) : (
      maintenance ? <div>
      This page is not available right now.
      </div> :
      <div className="grid sm:grid-cols-2 lg:grid-cols-3">
          {
            allConfigParams.map((param)=>(
              <ConfigParamCard
              key={param.id}
              id={param.id}
              paramName={param.param_name}
              paramValue={param.value}
              dataType={param.data_type}
              isPageCard={true}
              paramKey={param.key}
              setIsChanged={setIsChanged}
            />
            ))
          }
          <AddParamCard setIsAdded={setIsAdded}/>
      </div>
    )}
  </div>
  )
}

export default ConfigParam
