import AllCompletedPosts from "../../components/CompletedPostComponents/AllCompletedPosts/AllCompletedPosts";
import CompletedModal from "../../components/CompletedPostComponents/CompletedModal/CompletedModal";
import IpCompletedPosts from "../../components/CompletedPostComponents/IpCompletedPosts/IpCompletedPosts";
import { useState } from "react";
import TodayCompletedPosts from "../../components/CompletedPostComponents/TodayCompletedPosts/TodayCompletedPosts";
import { BiCalendarCheck, BiBadgeCheck, BiMap, BiFingerprint } from "react-icons/bi";
import FingerPosts from "../../components/CompletedPostComponents/FingerPosts/FingerPosts";

const Complete = () => {
  const [choice, setChoice] = useState("today");
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentId, setModalContentId] = useState();

  return (
    <div className="scroll-smooth w-full mt-2 sm:mt-5">
      <div className="rounded-md mt-5 text-sm sm:text-base md:text-sm xl:text-base text-center" role="group">
        <div className="flex w-full items-center justify-center flex-col sm:flex-row">
        <button  disabled={choice === "today"} onClick={() => setChoice("today")} className={`disabled:pointer-events-none  inline-flex disabled:bg-second items-center px-4 py-2 bg-transparent border  mt-4 md:mt-0 rounded lg:rounded-r-none sm:rounded-r-none lg:rounded-l-md focus:z-10 focus:ring-2 focus:ring-gray-50 focus:text-white border-second transition duration-200 disabled:text-white text-[#6C7291] hover:bg-main focus:bg-main`}>
          <BiCalendarCheck className="w-[1.5rem] h-[1.5rem]" />
          <p className="ml-2 sm:hidden lg:block">Today's Contents</p>
        </button>

        <button disabled={choice === "all" ? true : false} onClick={() => setChoice("all")} className="disabled:pointer-events-none disabled:bg-second inline-flex items-center px-4 py-2 bg-transparent border mt-4 md:mt-0 rounded sm:rounded-r-none sm:rounded-l-none  lg:rounded-l-none xl:rounded-none lg:mt-0 focus:z-10 focus:ring-2 focus:ring-gray-50 focus:text-white border-second transition duration-200 disabled:text-white text-[#6C7291] hover:bg-main focus:bg-main">
          <BiBadgeCheck className="w-[1.5rem] h-[1.5rem]" />
          <p className="ml-2 sm:hidden lg:block">Completed History</p>
        </button>

        <button disabled={choice === "fingerprint" ? true : false} onClick={() => setChoice("fingerprint")} className="disabled:pointer-events-none disabled:bg-second inline-flex items-center px-4 py-2 bg-transparent border mt-4 md:mt-0 rounded sm:rounded-r-none sm:rounded-l-none  lg:rounded-l-none xl:rounded-none lg:mt-0 focus:z-10 focus:ring-2 focus:ring-gray-50 focus:text-white border-second transition duration-200 disabled:text-white text-[#6C7291] hover:bg-main focus:bg-main">
          <BiFingerprint className="w-[1.5rem] h-[1.5rem]" />
          <p className="ml-2 sm:hidden lg:block">Sorted by Fingerprint's</p>
        </button>

        <button disabled={choice === "ip" ? true : false} onClick={() => setChoice("ip")} className="disabled:pointer-events-none disabled:bg-second inline-flex items-center px-4 py-2 bg-transparent border mt-4 md:mt-0 rounded xl:mt-0 xl:rounded-l-none lg:rounded-r-md focus:z-10 focus:ring-2 focus:ring-gray-50 focus:text-white border-second transition sm:rounded-l-none duration-200 disabled:text-white text-[#6C7291] hover:bg-main focus:bg-main">
          <BiMap className="w-[1.5rem] h-[1.5rem]" />
          <p className="ml-2 sm:hidden lg:block">Sorted by IP's</p>
        </button>
        </div>
      </div>
      <div className="sm:p-5 mt-5">
        {
            choice === "all" ? <AllCompletedPosts openModal={openModal} setModalContentId={setModalContentId} /> : choice === "today" ?  <TodayCompletedPosts openModal={openModal} setModalContentId={setModalContentId}/> : choice === "ip" ? <IpCompletedPosts /> : choice === "fingerprint" ? <FingerPosts/> : ""

        }

      <CompletedModal isOpen={isModalOpen} onClose={closeModal} contentId={modalContentId} /> 
      </div>
    </div>
  );
};

export default Complete;
