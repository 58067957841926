import React, { useEffect, useState, useRef } from "react";
import { BiMenu, BiUserCircle } from "react-icons/bi";
import UserImage from "../../../assets/images/user.png";
import { Link } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";
import useUser from "../../../hooks/useUser";

const NavBar = ({ toggleNav, setIsSignoutModalOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { base64Data } = useUser();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(dropdownRef);

  return (
    <div className="fixed top-0 px-5 py-3 bg-main w-full flex items-center shadow-2xl shadow-black md:hidden !z-[400]">
      <div className="shadow-none pr-[4.1rem]  flex items-center  bg-main">
        <h2 className="text-white text-xl sm:text-2xl tracking-[.3em]">
          WEBINEN
        </h2>
      </div>
      <div className="w-full flex justify-end p-2 gap-5">
        <div
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex relative items-center justify-center cursor-pointer"
        >
          {base64Data ? (
            <div className="w-8 h-8">
              <img
                src={`data:image/jpeg;charset=utf-8;base64,${base64Data}`}
                className="rounded-full w-full h-full object-cover"
              />
            </div>
          ) : (
            <div className="w-8 h-8 rounded-full bg-second animate-blink"></div>
          )}{" "}
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              className={`absolute font-[300] overflow-auto z-[999] top-[1.6rem] text-white text-sm mt-2 w-[150px] bg-main border-[0.2px] border-[#2B2E33] rounded-md shadow-lg ${
                isDropdownOpen
                  ? "animate-slideIn"
                  : "animate-slideOut opacity-0"
              }`}
            >
              <ul>
                <li>
                  <Link
                    className="p-2 hover:bg-[#0F1016] cursor-pointer border-[0.2px] border-[#2B2E33] flex items-center gap-2"
                    to="/profile"
                  >
                    <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                      <BiUserCircle
                        className="w-[1em] h-[1em]"
                        color="#0C6DFD"
                      />
                    </div>
                    <p>Profile</p>
                  </Link>
                </li>
                <li
                  onClick={() => setIsSignoutModalOpen(true)}
                  className="p-2 hover:bg-[#0F1016] cursor-pointer border-[0.2px] border-[#2B2E33] flex items-center gap-2"
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiLogOutCircle
                      className="w-[1em] h-[1em]"
                      color="#DC3444"
                    />
                  </div>
                  <p>Log out</p>
                </li>
              </ul>
            </div>
          )}
        </div>
        <button onClick={toggleNav} className="">
          <BiMenu className="w-[1.8rem] h-[1.8rem] text-second" />
        </button>
      </div>
    </div>
  );
};

export default NavBar;

// <button
// // onClick={toggleNav}
// className="!bg-transparent sidenav---sidenav-toggle---1KRjR block md:hidden"
// >
// <span className="sidenav---icon-bar---u1f02"></span>
// <span className="sidenav---icon-bar---u1f02"></span>
// <span className="sidenav---icon-bar---u1f02"></span>
// </button>
