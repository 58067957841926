import React, {useState} from 'react'
import { BiPlusCircle } from 'react-icons/bi'
import AddConfigModal from '../AddConfigModal/AddConfigModal';
const AddNewConfigCard = ({setIsChanged}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <div
      onClick={()=>setIsOpen(true)}
      className={`hover:scale-105 transition cursor-pointer bg-main p-4 md:p-5 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-base sm:text-xl">
            Add New Config
        </div>
        <div className="flex items-center justify-center">
          <BiPlusCircle className="w-[2rem] h-[2rem] sm:w-[3.6rem] sm:h-[3.6rem]" color="#198753" />
        </div>
      </div>
    </div>
    <AddConfigModal setIsChanged={setIsChanged} isOpen={isOpen} setIsOpen={setIsOpen}/>
    </>
  )
}

export default AddNewConfigCard
