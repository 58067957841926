import snoowrap from 'snoowrap';

export const fetchNewRedditContents = async (setActiveReddit) => {
    const subreddits = ["story", "TrueOffMyChest","IWantToLearn", "Futurology", "Showerthoughts", "unpopularopinion", "dating", "AskMen", "DecidingToBeBetter"]
    const GetRedditClient = async ( {clientId, clientSecret, username, password} ) => {
        const r = new snoowrap({
            userAgent: 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Mobile Safari/537.36',
            clientId: clientId,
            clientSecret: clientSecret,
            username: username,
            password: password
          });
        return r;
    }
    
    

    const GetNewAndHotUniqueContent = async (r, subreddit) => {

        const selectedSubreddit = await r.getSubreddit(subreddit)
        let hotRedditContents = selectedSubreddit.getHot();
        let newRedditContents = selectedSubreddit.getNew();
  
        const hotContents = getOnlyRequiredParams(hotRedditContents);
        const newContents = getOnlyRequiredParams(newRedditContents);
  
        const contents = hotContents.concat(newContents);
  
        return contents;
  }
  

    const getOnlyRequiredParams = (contents) => {
        let resultContents = contents.map(({ title,selftext,author,id,subreddit,ups,downs}) => {
          return {
            title: title,
            text: selftext,
            author: author.name,
            post_id: id,
            subreddit: subreddit.display_name,
            ups: ups,
            downs: downs
          }
        });
        return resultContents;
      }
      
  
  let loginData = {
    clientId: "s8uYgBunqHz-Xn8pEYIcDg",
    clientSecret: "ojokNoAzIxIhWmfkZKy0eqyyroYb4A",
    username: "furkaanpnr",
    password: "220183fg"
  }

  let r = await GetRedditClient(loginData);

  // const newContents = await RedditManager.GetNewAndHotUniqueContent(r, subreddits.at(0));
  
  var contents = [];
  for (var i = 0; i < subreddits.length; i++) {
    setActiveReddit(subreddits.at(i));
    const newContents = await GetNewAndHotUniqueContent(r, subreddits.at(i));
    contents = contents.concat(newContents);
  }
  setActiveReddit("finished");
  
    return contents;

  // Burada sistemde varmı kontrolü yapmak lazım
  // api/v1/reddit/post/{post_id}/exist || => || Boolean 
  // eliminasyon yapıldıktan sonra (animasyonlu) save ya da discard et butonları olamalı

}

// fetchNewRedditContents()