import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { LoadingProvider } from "./context/LoadingProvider";
import { PostProvider } from "./context/PostProvider";
import { ConfigProvider } from "./context/ConfigProvider";
import { UserProvider } from "./context/UserProvider";
import { AccountProvider } from "./context/AccountProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <UserProvider>
        <LoadingProvider>
          <PostProvider>
            <ConfigProvider>
              <AccountProvider>
              <App />
              </AccountProvider>
            </ConfigProvider>
          </PostProvider>
        </LoadingProvider>
      </UserProvider>
    </AuthProvider>
  </BrowserRouter>
);
