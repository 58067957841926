import React, { useRef, useEffect, useState } from "react";
import {
  BiLoaderCircle,
  BiPlus,
  BiSolidCog,
  BiX,
  BiXCircle,
} from "react-icons/bi";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import ConfigParamCard from "../ConfigParamCard/ConfigParamCard";
const ConfigModal = ({
  id,
  isOpen,
  setIsOpen,
  configParams,
  name,
  allParams,
  createdAt,
  lastUpdated,
  contentType,
  vpsAccounts,
  setIsChanged,
}) => {
  const modalRef = useRef(null);
  const [filteredParams, setFilteredParams] = useState([]);
  const [selectedVPS, setSelectedVPS] = useState("");
  const { auth } = useAuth();
  const [loading, setLoading] = useState();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(modalRef);

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Europe/Istanbul",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateTimeString)
    );
    return formattedDate;
  };

  const filterParams = (allParams, configParams) => {
    const configParamsIds = new Set(configParams.map((item) => item.id));
    const difference = allParams.filter(
      (item) => !configParamsIds.has(item.id)
    );
    setFilteredParams(difference);
  };

  useEffect(() => {
    isOpen && filterParams(allParams, configParams);
  }, [isOpen]);

  const setConfigToVps = () => {
    if (selectedVPS) {
      axios
        .put(`api/v1/config/${id}/vps/${selectedVPS}/set`, null, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deleteConfig = () => {
    setLoading(true);
    axios
      .delete(`api/v1/config/content/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setIsChanged(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const removeParamFromConfig = (paramId) => {
    setLoading(true);
    axios
      .put(`api/v1/config/content/${id}/remove/${paramId}`, null, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setIsChanged(true);
      })
      .catch((err) => {
        setIsChanged(true);
        setLoading(false);
        console.log(err);
      });
  };

  const addParamToConfig = (paramId) => {
    setLoading(true);
    axios
      .put(`api/v1/config/content/${id}/add/${paramId}`, null, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setIsChanged(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setIsChanged(false);
  }, []);
  return (
    <div
      ref={modalRef}
      className={`${
        isOpen ? "absolute animate-fadeIn0" : "hidden animate-fadeOut"
      } duration-300 top-1/2 left-1/2 transform -translate-y-[45%] md:-translate-y-1/2 -translate-x-1/2 w-[300px] md:w-[450px] lg:w-[700px] max-h-[500px] sm:max-h-[600px] overflow-y-scroll bg-main shadow-3xl rounded flex p-5 justify-start flex-col scrollbar-hide gap-5 z-[99]`}
    >
      <BiXCircle
        onClick={() => setIsOpen(false)}
        className="absolute right-5 top-6 w-[1.5rem] h-[1.5rem] cursor-pointer text-[#FB4149] hover:text-red-600 duration-200 hover:scale-110"
      />
      <div className="flex flex-col items-center gap-3 justify-center">
        <h2 className="sm:text-xl">
          {name} ({contentType})
        </h2>
        <p className="text-xs sm:text-sm text-second">
          Created at: {formatDate(createdAt)}
        </p>
        <p className="text-xs sm:text-sm text-second -mt-3">
          Last updated at: {formatDate(lastUpdated)}
        </p>
      </div>

      <div className="select-area">
        <label htmlFor="vps" className="text-white text-sm sm:text-base">
          Set a vps to this config:
        </label>

        <select
          id="vps"
          name="vps"
          className="block text-sm sm:text-base w-full mt-3 py-2 px-3 bg-black rounded shadow-sm focus:ring focus:ring-second focus:outline-none"
          value={selectedVPS}
          onChange={(e) => setSelectedVPS(e.target.value)}
        >
          <option value="">Select VPS</option>
          {vpsAccounts?.map((acc) => (
            <option key={acc.id} value={acc.id}>{acc.firstname}</option>
          ))}
        </select>

        {selectedVPS !== "" && (
          <div className="w-full flex justify-end">
            <button
              className={`text-sm sm:text-base animate-fadeIn0 rounded bg-[#1D302B] py-2 px-3 mt-5 duration-200 hover:bg-[#00D25A]`}
              onClick={setConfigToVps}
            >
              Confirm
            </button>
          </div>
        )}
      </div>

      <h2 className="text-sm sm:text-base mt-4">Vps Config Params:</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 bg-black rounded">
        <div className="flex flex-col gap-3 p-5">
          <h2 className="text-sm sm:text-lg">Parameters</h2>
          <div className="flex flex-col gap-5 max-h-[400px] pr-5 overflow-y-scroll overflow-x-hidden">
            {configParams.map((param) => (
              <ConfigParamCard
                key={param.id}
                id={param.id}
                paramName={param.param_name}
                status={"remove"}
                removeParamFromConfig={removeParamFromConfig}
                paramValue={param.value}
                dataType={param.data_type.data_type_name}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-3 p-5">
            <h2 className="text-sm sm:text-lg">All parameters</h2>
            <div className="flex flex-col gap-5 max-h-[500px] pr-5 overflow-y-scroll overflow-x-hidden">
              {filteredParams.map((param) => (
                <ConfigParamCard
                  key={param.id}
                  id={param.id}
                  paramName={param.param_name}
                  status={"add"}
                  addParamToConfig={addParamToConfig}
                  paramValue={param.value}
                  dataType={param.data_type.data_type_name}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          disabled={loading ? true : false}
          onClick={deleteConfig}
          className="text-sm w-full flex items-center justify-center bg-[#DC3444] hover:bg-red-600 duration-200 rounded py-2"
        >
          {loading ? (
            <BiLoaderCircle className="w-[1.5rem] h-[1.5rem] animate-spin" />
          ) : (
            "Delete This Config"
          )}
        </button>
      </div>
    </div>
  );
};

export default ConfigModal;
