import React, { useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import AddAccountModal from "../AddAccountModal/AddAccountModal";

const AddAccountCard = ({ type, setIsChanged }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <div
      onClick={() => setIsOpen(true)}
      className={`hover:scale-105 transition cursor-pointer bg-main p-4 md:p-7 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`}
    >
      <div className="flex justify-between">
        <div className="text-base sm:text-lg flex flex-col justify-center">
          <h2 className="">Add new account</h2>
        </div>
        <div className="bg-second px-1 py-1 lg:py-7 lg:px-5 rounded">
          <BiBookAdd className="text-white duration-300 w-[2rem] h-[2rem]" />
        </div>
      </div>
    </div>
    <AddAccountModal type={type} isOpen={isOpen} setIsOpen={setIsOpen} setIsChanged={setIsChanged}/>
    </>
  );
};

export default AddAccountCard;
