import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { BiFingerprint } from "react-icons/bi";

const IpCard = ({ ip, openModal, setModalIp, postCount, fingerPrint }) => {

  return (
    <div
      onClick={() => {
        if (ip) {
          setModalIp(ip);
          openModal();
        }
        if (fingerPrint) {
          setModalIp(fingerPrint);
          openModal();
        }

      }}
      className="hover:scale-105 transition duration-300 cursor-pointer bg-main p-4 md:p-7 rounded m-4 flex flex-col justify-center"
    >
      <div className="flex justify-between">
        <div className="text-base sm:text-xl flex flex-col justify-center">
          <p className="font-semibold">{ip ? ip : fingerPrint.slice(0,15) + '...'}</p>
          <p className="text-xs sm:text-sm text-[#8C8C8C]">Posted {postCount} posts</p>
        </div>
          {
            ip ?          
            <div className="bg-[#198753] px-1 py-1 lg:py-7 lg:px-5 rounded">
            <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="feather feather-map-pin"
          >
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
            <circle cx="12" cy="10" r="3" />
          </svg>
          </div>
          :
          <div className="bg-[#0C6DFD] px-1 py-1 lg:py-7 lg:px-5 rounded">
          <BiFingerprint className="w-10 h-10" />
          </div>
          }

      </div>
    </div>
  );
};

export default IpCard;
