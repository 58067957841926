import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  return (
    <div className="bg-black">
      <div className="flex items-center justify-center h-screen w-screen">
        <div className="bg-main rounded py-12 px-10 w-96 text-2xl text-white flex flex-col gap-5">
            <h2 className='text-3xl text-center'>404 NOT FOUND</h2>
            <p>Sorry, we don't have a page for this url... <span className='text-red-600'>{location.pathname}</span></p>
            <Link className='text-blue-500 text-lg hover:text-blue-800 transition' to="/">Click to return home page</Link>

        </div>
      </div>
    </div>
  )
}

export default ErrorPage;
