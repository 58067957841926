import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import { useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import config from '../../../config';
import useAuth from '../../../hooks/useAuth';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import PostDetailForm from '../../../components/AddPostComponents/PostDetailForm/PostDetailForm';
import LoadingSpinner from '../../../components/CommonComponents/LoadingSpinner/LoadingSpinner';

const PostDetails = () => {

    const navigate = useNavigate();

    const { auth } = useAuth();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [author, setAuthor] = useState('');
    const [onUse, setOnUse] = useState('');
    const [completed, setCompleted] = useState('False');
    const [createdAt, setCreatedAt] = useState('');
    const [postId, setPostId] = useState('');
    const [isReady, setIsReady] = useState(false);

    const handleComplete = () => {
        try {
            const response = axios.put(config.baseUrl + `api/v1/reddit/post/${postId}/complete`, {}, {
                headers: {
                    Authorization: `Bearer ${auth.accessToken}`
                }
            }
            );
            alert('Post completed successfully');
            navigate('/');

        } catch (error) {
            console.log(error.message);
            alert('An error occured');
        }
    };

    const handleRestore = () => {
        try {
            const response = axios.put(config.baseUrl + `api/v1/reddit/post/${postId}/restore`, {}, {
                headers: {
                    Authorization: `Bearer ${auth.accessToken}`
                }
            });
            alert('Post restored successfully');
            navigate('/');
        }
        catch (error) {
            console.log(error.message);
            alert('An error occured');
        }
    };

    useEffect(() => {
        getPostDetails();
    }, []);


    const getPostDetails = async () => {
        try {
            const response = await axios.get(config.baseUrl + `api/v1/reddit/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`
                    }
                });
            setTitle(response.data.data.title);
            setText(response.data.data.text);
            setAuthor(response.data.data.author);
            setOnUse(response.data.data.on_use);
            setCompleted(response.data.data.completed);
            response.data.data.created_at = response.data.data.created_at.substring(0, response.data.data.created_at.length - 10);
            setCreatedAt(response.data.data.created_at);
            setPostId(response.data.data.post_id);
            setIsReady(true);

        } catch (error) {
            console.log(error.message);

        }
    }

    return (
        <div className="w-full h-screen flex items-center justify-center py-[5rem]">
            {isReady == true ? 
                <PostDetailForm isReadOnly={true} title={title} author={author} onUse={onUse} completed={completed} createdAt={createdAt} postId={postId} handleComplete={handleComplete} handleRestore={handleRestore} text={text} />
                : <LoadingSpinner loading={true} />
            }
        </div>
    );
};

export default PostDetails;
