import React from 'react'

const OnUsePlaceholder = () => {
  return (
    <tr className="border-b border-b-[#2B2E33]">
    <td className="whitespace-nowrap px-4 py-4">
      <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
    <td className="whitespace-nowrap px-4 py-4">
    <div className="w-full h-full bg-[#515559] text-[#515559] animate-blink"> 
        .
      </div>
    </td>
</tr>
  )
}

export default OnUsePlaceholder
