import React from 'react';
import { animated, useTransition } from 'react-spring';

const PostDetailModal = ({ post, closeModal }) => {
  const transitions = useTransition(post, {
    from: { opacity: 0, transform: 'scale(0.2)', transformOrigin: 'center center' }, // Start small
    enter: { opacity: 1, transform: 'scale(1)', transformOrigin: 'center center' }, // End big
    leave: { opacity: 0, transform: 'scale(0.5)', transformOrigin: 'center center' }, // Start small again when leaving
  });

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-80 bg-black">
      {transitions(
        (props, item) =>
          item && (
            <animated.div
              style={props}
              className="bg-main rounded-lg p-6 max-w-md text-white shadow-lg"
            >
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold">Post Id: {item.post_id}</h2>
                <button
                  onClick={closeModal}
                  className="text-gray-300 hover:text-gray-100 transition-colors duration-200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h2 className="text-2xl font-bold mt-4">Title: {item.title}</h2>
              <p className="text-white mt-2">{item.text}</p>
              <div className="flex justify-between mt-4">
                <div>
                  <p className="text-gray-400">Author: {item.author}</p>
                  <p className="text-gray-400">Subreddit: {item.subreddit}</p>
                </div>
                <div>
                  <p className="text-green-400">Ups: {item.ups}</p>
                  <p className="text-red-400">Downs: {item.downs}</p>
                </div>
              </div>
              <button
                onClick={closeModal}
                className="bg-second text-white px-4 py-2 rounded-md mt-6 hover:bg-opacity-90 transition-colors duration-200"
              >
                Close
              </button>
            </animated.div>
          )
      )}
    </div>
  );
};

export default PostDetailModal;
