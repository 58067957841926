import React, { useEffect, useRef } from "react";
import { BiXCircle } from "react-icons/bi";

const VpsAccountModal = ({
  isOpen,
  setIsOpen,
  firstName,
  email,
  config,
  id,
}) => {
  const modalRef = useRef(null);

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Europe/Istanbul",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateTimeString)
    );
    return formattedDate;
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(modalRef);

  return (
    <div
      ref={modalRef}
      className={`${
        isOpen ? "absolute animate-fadeIn0" : "hidden animate-fadeOut"
      } duration-300 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 max-w-screen max-h-screen bg-main shadow-3xl rounded flex p-3 sm:p-5 justify-start flex-col gap-5 z-[99]`}
    >
      {" "}
      <BiXCircle
        onClick={() => setIsOpen(false)}
        className="absolute right-5 top-6 w-[1.5rem] h-[1.5rem] cursor-pointer text-[#FB4149] hover:text-red-600 duration-200 hover:scale-110"
      />
      <div className="flex items-center justify-center -gap-1 mb-1 flex-col">
        <h2 className="text-base sm:text-lg ">{firstName}</h2>
        <p className="text-xs sm:text-sm text-second">{email}</p>
      </div>
      <h2 className="text-base sm:text-lg ">Preset config</h2>
      <div className="flex flex-col gap-5 mt-0 pb-2 sm:pb-5 sm:px-5 px-2">
        <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
            <label className="justify-start">Name </label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={config.name}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-10 items-start text-sm sm:text-base sm:items-center ">
            <label>Content Type </label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={config.content_type.content_type_name}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-[3.8rem] items-start text-sm sm:text-base sm:items-center ">
            <label>Created At </label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={formatDate(config.created_at)}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-10 items-start text-sm sm:text-base sm:items-center ">
            <label>Last Updated</label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={formatDate(config.last_updated)}
              disabled
            />
          </div>
      </div>
    </div>
  );
};

export default VpsAccountModal;
