import React from "react";
import { CSSTransition } from "react-transition-group";
import "./CompletedModal.css";
import PostDetailForm from "../../AddPostComponents/PostDetailForm/PostDetailForm";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";

const CompletedModal = ({ isOpen, onClose, contentId }) => {
  const { auth } = useAuth();
  const [contentData, setContentData] = useState({});
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    if (contentId) {
      setLoading(true);
      axios
        .get(`api/v1/reddit/${contentId}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })
        .then((response) => {
          setContentData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const changeDateTime = (time) => {
    const originalDate = new Date(time);
    return `${originalDate.getFullYear()}-${String(
      originalDate.getMonth() + 1
    ).padStart(2, "0")}-${String(originalDate.getDate()).padStart(
      2,
      "0"
    )}T${String(originalDate.getHours()).padStart(2, "0")}:${String(
      originalDate.getMinutes()
    ).padStart(2, "0")}`;
  };
  useEffect(() => {
    isOpen && getData();
  }, [isOpen]);
  return (
    <CSSTransition in={isOpen} timeout={500} classNames="modal" unmountOnExit>
      <div className="fixed inset-0 bg-transparent flex items-center justify-center">
      <div onClick={onClose} className="w-full h-full absolute z-1">

      </div>
        <div className="bg-main sm:ml-[15rem] shadow-2xl shadow-black mt-16 sm:mt-0 relative rounded modal-content w-[80%] h-[530px] sm:w-[470px] sm:h-[530px] overflow-y-scroll !overflow-x-hidden">
          {loading ? (
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoadingSpinner loading={true} />
            </div>
          ) : (
            <>
              <div onClick={onClose} className="absolute top-6  sm:top-8  right-11 sm:right-14">
                <div className="fixed cursor-pointer ease-in-out hover:duration-300 text-[#FB4149] hover:text-red-700 hover:scale-110 rounded-full">
                <CloseIcon />
                </div>
              </div>

                <PostDetailForm
                  isReadOnly={true}
                  title={contentData.title}
                  author={contentData.author}
                  onUse={contentData.on_use}
                  completed={contentData.completed}
                  createdAt={changeDateTime(contentData.created_at)}
                  postId={contentData.id}
                  text={contentData.text}
                />
            </>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default CompletedModal;
