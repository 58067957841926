import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import useConfig from "../../../hooks/useConfig";
import {
  BiX,
  BiSolidHelpCircle,
  BiQuestionMark,
  BiLoader,
  BiLoaderCircle,
  BiInfoCircle,
} from "react-icons/bi";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import AddParamBasicInput from "./AddParamBasicInput";
import AddParamCheckbox from "./AddParamCheckbox";
import AddParamDate from "./AddParamDate";

const AddParamModal = ({ isModalOpen, setIsModalOpen, setIsAdded }) => {
  const { paramsArray, keysArray, dataTypes, contentTypes, setContentTypes } = useConfig();
  const inputRefs= [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),  
  ]
  const { auth } = useAuth();
  const [values, setValues] = useState([]);
  const [ct, setCt] = useState();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [keys, setKeys] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [formValue, setFormValue] = useState({
    name: "",
    key: "",
    data_type: "",
    value: ""
  });

  const handleInputKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      inputValue.trim() !== "" &&
      values.length < 5
    ) {
      setValues([...values, inputValue.trim()]);
      setInputValue("");
    }
  };
  useEffect(()=>{
    getKeysArray()
    setIsAdded(false);
    console.log(
    contentTypes[0]?.config_param_keys?.map((configParam) => (
      `${configParam.param_key_id}`
    )
    ))  }, []);

  useEffect(() => {
    inputRefs[0]?.current?.focus();
  }, [isModalOpen]);

  useEffect(() => {
    if (formValue.data_type !== "" && inputRefs[2]?.current) {
      inputRefs[2].current.focus();
    }
  }, [formValue.data_type]); 

  useEffect(() => {
    if (ct !== "" && inputRefs[1]?.current) {
      inputRefs[1].current.focus();
    }
  }, [ct]); 

  useEffect(()=>{
    if(ct === "" || formValue.key === "" ||  formValue.name === "" || formValue.data_type === "" || formValue.value == ""){
      setButtonDisabled(true)
    }
    else
     {
      setButtonDisabled(false)
     }
  }, [formValue])

  const handleInputChange = (event) => {
    setFormValue({...formValue, value: event.target.value});
  };

  const getKeysArray = () => {
    const keys = Array.from(keysArray);
    setKeys(keys)
  };

  const handleSave = async () => {
    setLoading(true);
    axios
      .post(
        "/api/v1/config/param",
        {
          param_name: formValue.name,
          key: formValue.key,
          value: formValue.value,
          data_type_id: parseInt(formValue.data_type),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setIsAdded(true)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsAdded(true)
      });
  };

  return (
    <CSSTransition
      in={isModalOpen}
      timeout={300}
      classNames="modal"
      unmountOnExit
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-full absolute z-1"
        ></div>
        <div
          className={`bg-black w-[95%] mt-[4rem] sm:mt-0 z-[3] sm:w-[50%]  lg:w-1/4  md:ml-[15rem] border border-[rgb(44,46,51)] rounded shadow-lg transform translate-y-0 transition-transform ease-in duration-300`}
        >
          <div className="text-white text-lg p-5 border-b border-[rgb(44,46,51)] flex items-center justify-between w-full">
            <div>
              <h2 className="text-basel sm:text-lg">Add New Config Param</h2>
            </div>
            <BiX
              onClick={() => setIsModalOpen(false)}
              className="cursor-pointer w-[1.5rem] h-[1.5rem] hover:scale-110 duration-300"
              color="rgb(44,53,51)"
            />
          </div>

          <div className="text-white p-3 sm:p-5 border-b border-[rgb(44,46,51)] flex flex-col gap-3">
          <div className={`flex text-[#FFAB00] flex-row gap-3 items-center transform transition-all ease-in-out duration-300 ${errMsg ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-2rem] h-0 overflow-hidden'}`}>
        <BiInfoCircle className="w-[1.5rem] h-[1.5rem]"/>
        {errMsg}
      </div>{" "}
            <div className="flex flex-col gap-2 items-start text-sm sm:text-base ">
              <label className="justify-start">Name </label>
              <input
                name="param_name"
                ref={inputRefs[2]}
                disabled = {(formValue.data_type.length < 1 ) ? true : false}
                className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition disabled:opacity-40 border-0 focus:outline-[#10CAF0] outline-none "
                placeholder="Parameter Name"
                required
                value={formValue.name}
                onChange={(e) => {
                  setFormValue({ ...formValue, name: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-col gap-2 items-start text-sm sm:text-base">
              <label className="justify-start flex gap-2 items-center group relative">
                Key
              </label>
              <select
                name="key"
                className="bg-[#2A3038] disabled:text-gray-400 px-3 py-2 rounded text-sm text-white w-full transition disabled:opacity-40 border-0 focus:outline-[#10CAF0] outline-none"
                required
                ref={inputRefs[3]}
                value={formValue.key}
                onChange={(e) => {
                  setFormValue({ ...formValue, key: e.target.value });
                }}
                disabled = {(formValue.data_type.length < 1 || formValue.name.length < 1) ? true : false}
              >
                <option value="">Select Key Type</option>
                {
                  ct === '1' ?
                  contentTypes[0]?.config_param_keys?.map((configParam) => (
                    <option key={configParam.param_key_id} value={configParam.param_key}>
                      {configParam.param_key}
                    </option>
                  ))
                  :
                  ct === '2' ?
                  contentTypes[1]?.config_param_keys?.map((configParam) => (
                    <option key={configParam.param_key_id} value={configParam.param_key}>
                    {configParam.param_key}
                  </option>    
                  ))
                  :
                  ''
                }

                </select>
            </div>

            <div className="flex flex-col gap-2 items-start text-sm sm:text-base">
              <label className="justify-center flex items-center">
                Value{" "}
                {
                  formValue.data_type === "9" ?
                  <span className="text-[10px] text-[#10CAF0]">
                  *You should type || between accounts.
                </span>
                : formValue.data_type === '6' ?
                <span className="text-[10px] text-[#10CAF0]">
                *You should type [ ]. 
              </span>
              :
              formValue.data_type === '7' ?
              <span className="text-[10px] text-[#10CAF0]">
              *You should type a dictionary
            </span>
              :
              ''
                }              
              </label>
              {
                (formValue.data_type === "1" || formValue.data_type === "2" || formValue.data_type === "3" || formValue.data_type === "4") ? <AddParamBasicInput
                  dataType={formValue.data_type}
                  value = {formValue.value}
                  onChange={handleInputChange}
                  formValue={formValue}
                  setFormValue={setFormValue}
                  disabled = {(formValue.data_type.length < 1 || formValue.name.length < 1 || formValue.key.length < 1) ? true : false}
                  /> 
                  :              
                  formValue.data_type === "5" ?
                  <AddParamCheckbox 
                  setFormValue={setFormValue}
                  formValue={formValue}
                  disabled = {(formValue.data_type.length < 1 || formValue.name.length < 1 || formValue.key.length < 1) ? true : false}
                  />
                  :
                  formValue.data_type === "8" ?
                  <AddParamDate 
                  value={formValue.value}
                  onChange={handleInputChange}
                  disabled = {(formValue.data_type.length < 1 || formValue.name.length < 1 || formValue.key.length < 1) ? true : false}
                  setFormValue={setFormValue}
                  formValue={formValue}
                  />
                  :
                  <input
                  name="value"
                  className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition disabled:opacity-40 border-0 focus:outline-[#10CAF0] outline-none"
                  placeholder="Value"
                  maxLength="15"
                  value={formValue.value}
                  onChange={handleInputChange}
                  disabled = {(formValue.data_type.length < 1 || formValue.name.length < 1 || formValue.key.length < 1) ? true : false}
                />
              }

              <div className="flex flex-row gap-2 flex-wrap">
                {values?.map((value) => (
                  <span className="border border-[#10CAF0] text-[#10CAF0] rounded px-3 py-1 text-sm">
                    {value}
                  </span>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-2 items-start text-sm sm:text-base">
              <label className="justify-start">Data Type</label>
              <select
                name="data_type"
                className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition border-0 focus:outline-[#10CAF0] outline-none disabled:opacity-40"
                required
                ref={inputRefs[1]}
                value={formValue.data_type}
                onChange={(e) =>
                  setFormValue({ ...formValue, data_type: e.target.value })
                }
                disabled={!ct ? true : false}
              >
                <option value="">Select Data Type</option>
                {dataTypes?.map((dataType) => (
                  <option key={dataType.data_type_id} value={dataType.data_type_id}>
                    {dataType.data_type_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-2 items-start text-sm sm:text-base">
              <label className="justiyfy-start">Content Type</label>
              <select
              name="content_type"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition border-0 focus:outline-[#10CAF0] outline-none"
              required
              ref={inputRefs[0]}
              value={ct}
              onChange={(e)=> {setCt(e.target.value)}}
              disabled={loading ? true : false}
              >
                <option value=''>Select Content Type</option>
                {
                  contentTypes.map((contentType)=>(
                    <option key={contentType.content_type_id} value={contentType.content_type_id}>
                      {contentType.content_type_name}
                    </option>

                  ))
                }
              </select>
            </div>
          </div>

          <div className="flex gap-5 items-center justify-end w-full p-3 sm:p-5">
            <button
              onClick={handleSave}
              className="bg-[#27A1EB] disabled:bg-blue-300 text-white w-20 h-8 flex items-center justify-center hover:bg-blue-500 duration-300 py-1 px-2 sm:px-3 rounded text-sm sm:text-base"
              disabled={buttonDisabled ? true : false}
            >
              {loading ? (
                <BiLoaderCircle className="w-[1.5rem] h-[1.5rem] animate-spin" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddParamModal;
