import React, { useEffect, useState } from "react";
import { BiPlus, BiSolidCog, BiX } from "react-icons/bi";
import ConfigParamModal from "../ConfigParamModal/ConfigParamModal";

const ConfigParamCard = ({
  id,
  paramName,
  status,
  removeParamFromConfig,
  addParamToConfig,
  paramValue,
  dataType,
  isPageCard,
  paramKey,
  setIsChanged
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const splitText = (text) => {
    let splittedArray = text.split("||");
    return splittedArray.map((sp, index) => {
      return (
        <span key={index}>
          <span>{sp}</span> <br />
        </span>
      );
    });
  };
  useEffect(()=>{

  })
  return (
    <>
      <div
        className={`${
          isPageCard
            ? "hover:scale-105 transition cursor-pointer bg-main p-4 md:p-5 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`"
            : "bg-main relative shadow-3xl rounded p-3 flex gap-4 flex-col"
        }`}
        onClick={isPageCard ? () => setIsModalOpen(true) : () => {}}
      >
        {!isPageCard && (
          <div className="flex items-center justify-between">
            <h2
              className={`${
                isPageCard
                  ? "text-sm sm:text-base text-second"
                  : "text-xs sm:text-sm"
              }`}
            >
              {paramName}
            </h2>
            {status === "remove" ? (
              <BiX
                onClick={() => {
                  removeParamFromConfig(id);
                }}
                className="w-[1.5rem] h-[1.5rem] hover:scale-125 duration-300 cursor-pointer"
                color="#FC424A"
              />
            ) : status === "add" ? (
              <BiPlus
                onClick={() => {
                  addParamToConfig(id);
                }}
                className="w-[1.5rem] h-[1.5rem] hover:scale-125 duration-300 cursor-pointer"
                color="#00d25a"
              />
            ) : (
              ""
            )}
          </div>
        )}

        <div className="flex items-center justify-between">
          <div
            className={`${
              isPageCard ? "text-base sm:text-xl" : "text-sm sm:text-base"
            }`}
          >
            {isPageCard ? (
              <p className="text-sm sm:text-lg">{paramName}</p>
            ) : (
              <>
                <p>
                  {paramValue?.includes("||")
                    ? splitText(paramValue)
                    : paramValue}
                </p>
                <p className="text-xs sm:text-sm text-second">{dataType}</p>
              </>
            )}
          </div>
          <div>
            <BiSolidCog
              color="#10CAF0"
              className={`${
                isPageCard
                  ? "w-[2rem] h-[2rem] sm:w-[3rem] sm:h-[3rem]"
                  : "w-[2rem] h-[2rem]"
              }`}
            />
          </div>
        </div>
      </div>
      {
        isPageCard &&
        <ConfigParamModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        paramName={paramName}
        paramValue={paramValue}
        dataType={dataType.data_type_name}
        id={id}
        paramKey={paramKey}
        setIsChanged={setIsChanged}
      />
      }

    </>
  );
};

export default ConfigParamCard;
