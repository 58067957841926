import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import useInput from "../../hooks/useInput";
import useToggle from "../../hooks/useToggle";
import axios from "../../api/axios";
import { CSSTransition } from "react-transition-group";
import useLoading from "../../hooks/useLoading";
import LoadingSpinner from "../../components/CommonComponents/LoadingSpinner/LoadingSpinner";

const LOGIN_URL = "/api/v1/auth/authenticate";
const Login = () => {
  const { auth, setAuth } = useAuth();
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [user, resetUser, userAttribs] = useInput("user", "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [check, toggleCheck] = useToggle("persist", false);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        LOGIN_URL,
        {
          email: user,
          password: pwd,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.access_token;
      setAuth({ user, accessToken });
      resetUser();
      setPwd("");
      setLoading(false);
      navigate(from, { replace: true });
    } catch (err) {
      setErrMsg(`${err}"username or password wrong"`);
      setLoading(false);
    }
  };

  return (
    <div className="bg-black">
      <div className="flex items-center justify-center h-screen w-screen">
        {loading ? (
          <div className="w-screen h-screen bg-black">
            <LoadingSpinner loading={true} />
          </div>
        ) : (
          <div className="form-container bg-main rounded py-12 px-10 w-96">
            <form onSubmit={handleLogin}>
              <div className="form-heading text-white">
                <p
                  className={
                    errMsg
                      ? "bg-black text-center p-2 rounded mb-5 transition duration-300 ease-in-out"
                      : "hidden"
                  }
                >
                  {errMsg}
                </p>

                <h2 className="text-4xl tracking-widest">WEBINEN</h2>
                <p className="mt-5 font-semibold text-lg">Process Monitoring</p>
                <p className="font-light text-sm">Sign in to contintue.</p>
              </div>
              <div className="form-inputs mt-5 flex flex-col gap-5">
                <input
                  type="text"
                  placeholder="Username"
                  {...userAttribs}
                  required
                  className="placeholder:text-second bg-transparent border border-second border-opacity-20 rounded py-3 px-4  ease-in-out  text-white transition outline-none focus:border-blue-500 active:border-blue-500"
                />
                <input
                  type="password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  required
                  placeholder="Password"
                  className="placeholder:text-second bg-transparent border border-second border-opacity-20 rounded py-3 px-4  ease-in-out  text-white transition outline-none focus:border-blue-500 active:border-blue-500"
                />
              </div>

              <button
                className="mt-8 text-center bg-blue-500 text-white w-full py-3 rounded transition ease-in-out hover:bg-blue-700 active:bg-blue-700 focus:border-none focus:outline-none"
                type="submit"
              >
                SIGN IN
              </button>

              <div className="form-checkbox flex items-center mt-5 gap-3">
                <input
                  type="checkbox"
                  className="w-4 h-4 accent-blue-500 focus:border-none focus:outline-none"
                  onChange={toggleCheck}
                  id="persist"
                  checked={check}
                />
                <span className="text-second text-sm "> Keep me signed in</span>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
