import React, { useEffect } from "react";

const AddParamBasicInput = ({ maxLength, value, onChange, dataType, disabled, formValue, setFormValue}) => {

  useEffect(()=>{
    setFormValue({...formValue, value: ""})
  }, [dataType])
  return (
    <input
      name="value"
      className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition disabled:opacity-40 border-0 focus:outline-[#10CAF0] outline-none"
      placeholder="Custom Input"
      maxLength={dataType === "1"  ? "1" : dataType === "2" ? "15" : "20" }
      step={dataType === "4" ? "0.01" : undefined}
      type={dataType === "1" ? "text" : dataType === "2" ? "text" : dataType === "3" ? "number" : dataType === "4" ? "number": "color"}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default AddParamBasicInput;
