import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import useAuth from "../../../hooks/useAuth";
import HomeOnUse from "../HomeOnUse/HomeOnUse";
import OnUsePlaceholder from "../OnUsePlaceholder/OnUsePlaceholder";

const OnUseTable = ({ setIsOnUseChanged }) => {
  const [onUseData, setOnUseData] = useState([]);
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(config.baseUrl + "api/v1/on-use", {
          headers: {
            authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          if (res.data.data.length === onUseData.length) {
            setOnUseData(res.data.data);
          } else {
            console.log("data degisti")
            setOnUseData(res.data.data);
            setIsOnUseChanged(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 3000);
    return () => clearInterval(interval);
  }, [onUseData, auth.accessToken]);

  useEffect(() => {
    getOnUseData();
  }, []);

  useEffect(() => {
    if (isChanged) {
      getOnUseData();
    }
  }, [isChanged]);

  const getOnUseData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(config.baseUrl + "api/v1/on-use", {
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (JSON.stringify(response.data.data) === JSON.stringify(onUseData)) {
        setLoading(false);
        return;
      }
      setOnUseData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="bg-main rounded p-5 overflow-x-scroll">
      <h2 className="text-base sm:text-lg mb-3">Content On Use</h2>
      <table className="min-w-full text-left text-second">
        <thead className="border-b border-b-[#2B2E33] text-xs sm:text-[15px]">
          <tr>
            <th scope="col" className="px-4 py-4 font-[600]">
              Content Id
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Content Type
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Ip Address
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Fingerprint
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              On Use At
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Ready At
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Status
            </th>
            <th scope="col" className="px-4 py-4 font-[600]">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="text-[14px]">
          {loading ? (
            <>
              <OnUsePlaceholder />
              <OnUsePlaceholder />
            </>
          ) : (
            onUseData
              .sort((a, b) => new Date(b.on_use_at) - new Date(a.on_use_at)) 
              .map((data) => (
                <HomeOnUse
                  setIsChanged={setIsChanged}
                  data={data}
                  key={data.content_id}
                />
              )) 
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OnUseTable;
