import React, { useEffect, useState, useRef } from "react";
import ProfileImage from "../../assets/images/user.png";
import { BiCrown, BiLogoGmail, BiPlus, BiSolidIdCard } from "react-icons/bi";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import LoadingSpinner from "../../components/CommonComponents/LoadingSpinner/LoadingSpinner";
import useUser from "../../hooks/useUser";
const Profile = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState();
  const [image, setImage] = useState(null);
  const inputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { user, setUser, base64Data, setBase64Data } = useUser();

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleImageUpload = async (e) => {
    if (!image) {
      console.error("Lütfen bir resim seçin.");
      return;
    }

    const maxImageSize = 500;

    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      if (img.width > maxImageSize || img.height > maxImageSize) {
        alert(
          `Resmin boyutları ${maxImageSize}x${maxImageSize} pikseli aşamaz.`
        );
        setImage(null);
        return;
      }
    };

    const formData = new FormData();
    formData.append("image", image);

    axios
      .post(`api/v1/user/profile-image/save`, formData, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsChanged(true);
        if (inputRef.current) {
          inputRef.current.value = null;
        }
        setImage(null);
      })
      .catch((error) => {
        setImage(null);
        setIsChanged(true);
      });
    setIsChanged(false);
  };

  useEffect(() => {
    if (isChanged) {
      getUser();
    }
  }, [isChanged]);

  const getUser = async () => {
    setLoading(true);
    axios
      .get("api/v1/user/current", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setUser(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="flex items-center justify-center">
      {loading ? (
        <LoadingSpinner loading={true} />
      ) : (
        <div className="p-5 bg-main flex items-center flex-col gap-1 rounded">
          <div
            className={`relative rounded-full w-24 h-24`}
            onMouseLeave={toggleHover}
            onMouseEnter={toggleHover}
          >
            {base64Data ? (
              <div className="w-24 h-24">
                <img
                  src={`data:image/jpeg;charset=utf-8;base64,${base64Data}`}
                  className={`${
                    isHovered ? "blur" : ""
                  } rounded-full w-full h-full duration-300 object-cover`}
                />
              </div>
            ) : (
              <div className="w-24 h-24 rounded-full bg-second animate-blink"></div>
            )}

            {isHovered && (
              <label
                htmlFor="image-upload"
                className="cursor-pointer absolute inset-0 flex items-center justify-center"
              >
                <BiPlus
                  className={`w-[4rem] h-[4rem] text-main ${
                    isHovered ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-500`}
                />
              </label>
            )}
          </div>

          <div className="text-white text-xl mt-3">
            {user.firstname} {user.lastname}
          </div>
          <div className="flex gap-2 items-center justify-start w-full mt-3">
            <BiSolidIdCard className="w-[1.3rem] h-[1.3rem]" />
            <p className="text-sm text-second">{user.id}</p>
          </div>
          <div className="flex gap-2 items-center justify-start w-full mt-1">
            <BiCrown className="w-[1.3rem] h-[1.3rem]" />
            <p className="text-sm text-second">{user.role}</p>
          </div>
          <div className="flex gap-2 items-center justify-start w-full mt-1">
            <BiLogoGmail className="w-[1.3rem] h-[1.3rem]" />
            <p className="text-sm text-second">{user.email}</p>
          </div>
          <input
            ref={inputRef}
            type="file"
            id="image-upload"
            accept=".png"
            style={{ display: "none" }}
            onChange={(event) => {
              setImage(event.target.files[0]);
            }}
          />
          {image && (
            <button
              className={`bg-second px-3 py-1 rounded w-full mt-5 text-sm hover:bg-slate-400 duration-200 hover:text-black`}
              onClick={handleImageUpload}
            >
              Upload Image
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
