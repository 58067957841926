import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Accounts = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    if (location.pathname!=="/accounts/tiktok"){
    navigate('/accounts/youtube');
    }
  }, [])
  return (
    <div className='w-full h-full'>
      <Outlet/>
    </div>
  )
}

export default Accounts
