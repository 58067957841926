import React, { useState } from 'react'
import { BiLogoAirbnb, BiSolidUser } from 'react-icons/bi'
import VpsAccountModal from '../VpsAccountModal/VpsAccountModal';

const VpsAccountCard = ({id, firstName, email, config}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <div
    onClick={() => {
        setIsOpen(true);
      }}
      className={`hover:scale-105 transition cursor-pointer bg-main p-4 md:p-7 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`}
    >
      <div className="flex justify-between">
        <div className="text-base sm:text-lg flex flex-col justify-center">
          <h2 className="">{firstName}</h2>
        </div>
        <div className={`bg-[#1D302B] px-1 py-1 lg:py-7 lg:px-5 rounded`}>
            {
                <BiSolidUser color='#00D25A' className='w-[2rem] h-[2rem]'/>         
            }
        </div>
      </div>
    </div>
        <VpsAccountModal isOpen={isOpen} setIsOpen={setIsOpen} id={id} firstName={firstName} email={email} config={config} />
    </>
  )
}

export default VpsAccountCard
