import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { Link, useLocation } from "react-router-dom";
import UserImage from "../../../assets/images/user.png";

import {
  BiHome,
  BiData,
  BiAddToQueue,
  BiStats,
  BiCheckCircle,
  BiDotsVerticalRounded,
  BiUserCircle,
  BiLogOutCircle,
  BiSolidUserAccount,
  BiSolidDownArrow,
  BiCloud,
} from "react-icons/bi";
import useUser from "../../../hooks/useUser";

const Sidebar = ({
  setIsSignoutModalOpen,
  isOpen,
  setIsOpen,
  toggleNav,
  user,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const location = useLocation();
  const { auth } = useAuth();
  const [active, setActive] = useState("/");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVpsExpanded, setIsVpsExpanded] = useState(false);
  const { base64Data } = useUser();

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleVpsExpansion = () => {
    setIsVpsExpanded(!isVpsExpanded);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    setIsDropdownOpen(false);
    setActive(window.location.pathname);
  }, [window.location.pathname]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }



  useOutsideAlerter(dropdownRef);
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#1A1C24",
          position: "fixed",
        }}
        className=" max-md:hidden h-full z-[99]"
      >
        <div className="pr-[4.1rem] px-5 flex items-center py-5">
          <h2 className="text-white text-2xl tracking-[.3em]">WEBINEN</h2>
        </div>
        <div className={`flex px-5 py-3 pt-0`}>
          <div className="flex items-center justify-center">
            { base64Data ? (
              <div className="w-10 h-10">
                <img
                  src={`data:image/jpeg;charset=utf-8;base64,${base64Data}`}
                  className="rounded-full w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-10 h-10 rounded-full bg-second animate-blink"></div>
            )}
          </div>
          <div className={`flex pl-3 justify-between items-center w-full`}>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute font-[300] -right-[7.3rem] overflow-auto z-[999] top-[6.3rem] text-white text-sm mt-2 w-[150px] bg-main border-[0.2px] border-[#2B2E33]  rounded-md shadow-lg"
              >
                <ul>
                  <li>
                    <Link
                      className="p-2 hover:bg-[#0F1016] cursor-pointer border-[0.2px] border-[#2B2E33] flex items-center gap-2"
                      to="/profile"
                    >
                      <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                        <BiUserCircle
                          className="w-[1em] h-[1em]"
                          color="#0C6DFD"
                        />
                      </div>
                      <p>Profile</p>
                    </Link>
                  </li>
                  <li
                    onClick={() => setIsSignoutModalOpen(true)}
                    className="p-2 hover:bg-[#0F1016] cursor-pointer border-[0.2px] border-[#2B2E33] flex items-center gap-2"
                  >
                    <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                      <BiLogOutCircle
                        className="w-[1em] h-[1em]"
                        color="#DC3444"
                      />
                    </div>
                    <p>Log out</p>
                  </li>
                </ul>
              </div>
            )}

            <div className="flex flex-col">
              <p className="text-white font-[300]">
                {user?.firstname} {user?.lastname}{" "}
              </p>
              <p className="text-[0.7rem] text-second">{user?.role}</p>
            </div>
            <BiDotsVerticalRounded
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-[1.2rem] h-[1.2rem] cursor-pointer dots-icon"
              color="#6C7292"
            />
          </div>
        </div>
        <div className="px-5 py-3 flex items-center">
          <p className="text-[#6C7292] text-sm font-semibold">Navigation</p>
        </div>

        <nav>
          <ul className="text-[#6C7292] text-sm">
            <li className="pr-5 relative">
              <Link
                to="/"
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                  active === "/"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                }`}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiHome className="w-[1.5em] h-[1.5em]" color="white" />
                </div>
                <p>Home</p>
              </Link>
              {active === "/" && (
                <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
              )}
            </li>
            <li className="pr-5 relative">
              <div
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] cursor-pointer ${
                  active === "/accounts" ||
                  active === "/accounts/youtube" ||
                  active === "/accounts/tiktok"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                } ${isExpanded ? "expanded" : ""}`}
                onClick={toggleExpansion}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiSolidUserAccount
                    className="w-[1.5em] h-[1.5em]"
                    color="white"
                  />
                </div>
                <p>Accounts</p>
                <BiSolidDownArrow
                  className={`ml-auto duration-300 ${
                    isExpanded && "rotate-[180deg]"
                  }`}
                />
              </div>
              <div
                className={`overflow-hidden transform transition-max-h duration-300 ${
                  isExpanded ? "max-h-[96px]" : "max-h-[0]"
                }`}
              >
                <div className="pl-[4rem] flex flex-col gap-2 my-1">
                  <Link
                    className={`hover:text-white duration-300 ${
                      active === "/accounts/youtube" && "text-white"
                    } `}
                    to="accounts/youtube"
                  >
                    Youtube
                  </Link>
                  <Link
                    className={`hover:text-white duration-300 ${
                      active === "/accounts/tiktok" && "text-white"
                    } `}
                    to="accounts/tiktok"
                  >
                    Tiktok
                  </Link>
                </div>
              </div>

              {(active === "/accounts" ||
                active === "/accounts/youtube" ||
                active === "/accounts/tiktok") && (
                <div className="absolute left-0 top-0 w-[3px] h-[47px] bg-[#0090e7]" />
              )}
            </li>
            <li className="pr-5 relative">
              <Link
                to="/database"
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                  active === "/database"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                }`}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiData className="w-[1.5em] h-[1.5em]" color="white" />
                </div>
                <p>Database</p>
              </Link>
              {active === "/database" && (
                <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
              )}
            </li>
            <li className="pr-5 relative">
              <Link
                to="/add-post"
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                  active === "/add-post"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                }`}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiAddToQueue className="w-[1.5em] h-[1.5em]" color="white" />
                </div>
                <p>Add Post</p>
              </Link>
              {active === "/add-post" && (
                <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
              )}
            </li>
            <li className="pr-5 relative">
              <Link
                to="/analytics"
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                  active === "/analytics"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                }`}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiStats className="w-[1.5em] h-[1.5em]" color="white" />
                </div>
                <p>Analytics</p>
              </Link>
              {active === "/analytics" && (
                <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
              )}
            </li>
            <li className="pr-5 relative">
              <Link
                to="/completed-posts"
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                  active === "/completed-posts"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                }`}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiCheckCircle
                    className="w-[1.5em] h-[1.5em]"
                    color="white"
                  />
                </div>
                <p>Completed Posts</p>
              </Link>
              {active === "/completed-posts" && (
                <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
              )}
            </li>

            <li className="pr-5 relative">
              <div
                className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] cursor-pointer ${
                  active === "/vps" ||
                  active === "/vps/config" ||
                  active === "/vps/config-param" ||
                  active === "/vps/accounts"
                    ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                    : ""
                } ${isVpsExpanded ? "expanded" : ""}`}
                onClick={toggleVpsExpansion}
              >
                <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                  <BiCloud className="w-[1.5em] h-[1.5em]" color="white" />
                </div>
                <p>Vps</p>
                <BiSolidDownArrow
                  className={`ml-auto duration-300 ${
                    isVpsExpanded && "rotate-[180deg]"
                  }`}
                />
              </div>
              <div
                className={`overflow-hidden transform transition-max-h duration-300 ${
                  isVpsExpanded ? "max-h-[96px]" : "max-h-[0]"
                }`}
              >
                <div className="pl-[4rem] flex flex-col gap-2 my-1">
                <Link
                    className={`hover:text-white duration-300 ${
                      active === "/vps/accounts" && "text-white"
                    } `}
                    to="vps/accounts"
                  >
                    Accounts
                  </Link>
                  <Link
                    className={`hover:text-white duration-300 ${
                      active === "/vps/config" && "text-white"
                    } `}
                    to="vps/config"
                  >
                    Config
                  </Link>
                  <Link
                    className={`hover:text-white duration-300 ${
                      active === "/vps/config-param" && "text-white"
                    } `}
                    to="vps/config-param"
                  >
                    Config Params
                  </Link>
                </div>
              </div>

              {(active === "/vps" ||
                active === "/vps/config" ||
                active === "/vps/config-param" ||
                active === "/vps/accounts"
                ) && (
                <div className="absolute left-0 top-0 w-[3px] h-[47px] bg-[#0090e7]" />
              )}
            </li>
          </ul>
        </nav>
      </div>

      {/* MOBILE NAVBAR md:hidden */}

      <div
        className={`${
          isOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden z-50 fixed top-[4.3rem] right-0 w-[50%] h-screen bg-main text-[#BBBBBD] transform transition-transform ease-in-out duration-300`}
      >
        {/* Yan menü */}
        {isOpen && (
          <div
            className={`absolute top-0 right-0 w-full h-screen bg-main text-[#BBBBBD] transform transition-transform ease-in-out duration-300  overflow-hidden z-999 ${
              isOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="px-5 py-3 flex items-center">
              <p className="text-[#6C7292] text-sm font-semibold">Navigation</p>
            </div>
            <ul className="text-[#6C7292] text-sm">
              <li className="pr-2 relative">
                <Link
                  to="/"
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                    active === "/"
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  }`}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiHome className="w-[1.5em] h-[1.5em]" color="white" />
                  </div>
                  <p>Home</p>
                </Link>
                {active === "/" && (
                  <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
                )}
              </li>
              <li className="pr-2 relative">
                <div
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem]${
                    active === "/accounts" ||
                    active === "/accounts/youtube" ||
                    (active === "/accounts/tiktok" &&
                      "bg-[#0F1016] rounded-r-[4rem] text-white")
                  } ${isExpanded ? "expanded" : ""}`}
                  onClick={toggleExpansion}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiSolidUserAccount
                      className="w-[1.5em] h-[1.5em]"
                      color="white"
                    />
                  </div>
                  <p>Accounts</p>
                  <BiSolidDownArrow
                    className={`ml-auto duration-300 ${
                      isExpanded && "rotate-[180deg]"
                    }`}
                  />
                </div>
                <div
                  className={`overflow-hidden transform transition-max-h duration-300 ${
                    isExpanded ? "max-h-[96px]" : "max-h-[0]"
                  }`}
                >
                  <div className="pl-[4rem] flex flex-col gap-2 my-1">
                    <Link
                      className={`hover:text-white duration-300 ${
                        active === "/accounts/youtube" && "text-white"
                      } `}
                      to="accounts/youtube"
                    >
                      Youtube
                    </Link>
                    <Link
                      className={`hover:text-white duration-300 ${
                        active === "/accounts/tiktok" && "text-white"
                      } `}
                      to="accounts/tiktok"
                    >
                      Tiktok
                    </Link>
                  </div>
                </div>

                {(active === "/accounts" ||
                  active === "/accounts/youtube" ||
                  active === "/accounts/tiktok") && (
                  <div className="absolute left-0 top-0 w-[3px] h-[47px] bg-[#0090e7]" />
                )}
              </li>
              <li className="pr-2 relative">
                <Link
                  to="/database"
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                    active === "/database"
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  }`}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiData className="w-[1.5em] h-[1.5em]" color="white" />
                  </div>
                  <p>Database</p>
                </Link>
                {active === "/database" && (
                  <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
                )}
              </li>
              <li className="pr-2 relative">
                <Link
                  to="/add-post"
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                    active === "/add-post"
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  }`}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiAddToQueue
                      className="w-[1.5em] h-[1.5em]"
                      color="white"
                    />
                  </div>
                  <p>Add Post</p>
                </Link>
                {active === "/add-post" && (
                  <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
                )}
              </li>
              <li className="pr-2 relative">
                <Link
                  to="/analytics"
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                    active === "/analytics"
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  }`}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiStats className="w-[1.5em] h-[1.5em]" color="white" />
                  </div>
                  <p>Analytics</p>
                </Link>
                {active === "/analytics" && (
                  <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
                )}
              </li>
              <li className="pr-2 relative">
                <Link
                  to="/completed-posts"
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem] ${
                    active === "/completed-posts"
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  }`}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiCheckCircle
                      className="w-[1.5em] h-[1.5em]"
                      color="white"
                    />
                  </div>
                  <p>Completed Posts</p>
                </Link>
                {active === "/completed-posts" && (
                  <div className="absolute left-0 top-0 w-[3px] h-[100%] bg-[#0090e7]" />
                )}
              </li>

              <li className="pr-2 relative">
                <div
                  className={`flex items-center gap-3 justify-start px-5 py-2 hover:bg-[#0F1016] hover:rounded-r-[4rem]${
                    (active === "/vps" ||
                    active === "/vps/config" ||
                    active === "/vps/config-param" ||
                    active === "/vps/accounts")
                      ? "bg-[#0F1016] rounded-r-[4rem] text-white"
                      : ""
                  } ${isVpsExpanded ? "expanded" : ""}`}
                  onClick={toggleVpsExpansion}
                >
                  <div className="bg-[#21242F] rounded-full flex items-center justify-center !p-1 w-[31px] h-[31px] ">
                    <BiCloud className="w-[1.5em] h-[1.5em]" color="white" />
                  </div>
                  <p>Vps</p>
                  <BiSolidDownArrow
                    className={`ml-auto duration-300 ${
                      isVpsExpanded && "rotate-[180deg]"
                    }`}
                  />
                </div>
                <div
                  className={`overflow-hidden transform transition-max-h duration-300 ${
                    isVpsExpanded ? "max-h-[96px]" : "max-h-[0]"
                  }`}
                >
                  <div className="pl-[4rem] flex flex-col gap-2 my-1">
                  <Link
                    className={`hover:text-white duration-300 ${
                      active === "/vps/accounts" && "text-white"
                    } `}
                    to="vps/accounts"
                  >
                    Accounts
                  </Link>
                    <Link
                      className={`hover:text-white duration-300 ${
                        active === "/vps/config" && "text-white"
                      } `}
                      to="vps/config"
                    >
                      Config
                    </Link>
                    <Link
                      className={`hover:text-white duration-300 ${
                        active === "/vps/config-param" && "text-white"
                      } `}
                      to="vps/config-param"
                    >
                      Config Params
                    </Link>
                  </div>
                </div>

                {(active === "/vps" ||
                  active === "/vps/config" ||
                  active === "/vps/config-param" ||
                  active === "/vps/accounts") && (
                  <div className="absolute left-0 top-0 w-[3px] h-[47px] bg-[#0090e7]" />
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
