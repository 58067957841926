import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/HomePage/Home";
import RequireAuth from "./components/AuthComponents/RequireAuth/RequireAuth";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PostDetails from "./pages/HomePage/PostDetails/PostDetails";
import PersistLogin from "./components/AuthComponents/PersistLogin/PersistLogin";
import MainLayout from "./layouts/MainLayout";
import Database from "./pages/Database/Database";
import AddPost from "./pages/AddPost/AddPost";
import Analytics from "./pages/Analytics/Analytics";
import CompletedPosts from "./pages/CompletedPosts/CompletedPosts";
import Profile from "./pages/Profile/Profile";
import Accounts from "./pages/Accounts/Accounts";
import YoutubeAccount from "./pages/Accounts/YoutubeAccount/YoutubeAccount";
import TiktokAccount from "./pages/Accounts/TiktokAccount/TiktokAccount";
import Config from "./pages/Vps/Config/Config";
import Vps from "./pages/Vps/Vps";
import ConfigParam from "./pages/Vps/ConfigParam/ConfigParam";
import VpsAccounts from "./pages/Vps/VpsAccounts/VpsAccounts";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
const App = () => {
  return (
    <>
      <Routes>
        {/* public routes */}
        <Route path="/login" index element={<Login/>}/>

        {/* protected routes */}
        <Route element={<PersistLogin/>}>
          <Route element={<MainLayout/>}>
              <Route element={<RequireAuth allowedRoles={["ADMIN", "USER"]}/>}>
                <Route path="/" element={<Home/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="/on-use/:id" element={<PostDetails/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="database" element={<Database/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="add-post" element={<AddPost/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="analytics" element={<Analytics/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN", "USER"]}/>}>
                <Route path="completed-posts" element={<CompletedPosts/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN", "USER"]}/>}>
                <Route path="profile" element={<Profile/>}/>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="accounts" element={<Accounts/>}>
                  <Route path="youtube" element={<YoutubeAccount/>} />
                  <Route path="tiktok" element={<TiktokAccount/>} />
                </Route>
              </Route>

              <Route element={<RequireAuth allowedRoles={["ADMIN"]}/>}>
                <Route path="vps" element={<Vps/>}>
                  <Route path="config" element={<Config/>} />
                  <Route path="accounts" element={<VpsAccounts/>} />
                  <Route path="config-param" element={<ConfigParam/>} />
                </Route>
              </Route>
          </Route>
          <Route>
              <Route path="unauthorized" element={<Unauthorized/>} />
          </Route>

          
        </Route>


        {/* catch all */}
        <Route path="*" element={<ErrorPage/>} />
      </Routes>
    </>
  );
}

export default App;
