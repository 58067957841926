import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import useConfig from "../../../hooks/useConfig";
import { BiX } from "react-icons/bi";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";

const ConfigParamModal = ({
  isModalOpen,
  setIsModalOpen,
  paramName,
  paramValue,
  dataType,
  id,
  paramKey,
  setIsChanged
}) => {
  const [loading, setLoading] = useState();
  const [configs, setConfigs] = useState();
  const { auth } = useAuth();

  const getAllConfigsByParam = () => {
    setLoading(true);
    axios
      .get(`api/v1/config/param/${id}/dependent`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setConfigs(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(()=>{
    setIsChanged(false);
  }, []);
  const deleteConfigParam = () => {
    setLoading(true);
    axios
    .delete(`/api/v1/config/param/${id}`,{
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((res) => {
      setLoading(false);
      setIsChanged(true)
    })
    .catch((err) => {
      setLoading(false);
      setIsChanged(true)
    });
  }

  useEffect(() => {
    isModalOpen && getAllConfigsByParam();
  }, [isModalOpen]);
  const splitText = (text) => {
    let splittedArray = text?.split("||");
    return splittedArray?.map((sp, index) => {
      return (
        <div key={index} className="my-2 bg-transparent border border-second text-[#10CAF0] py-2 rounded flex items-center justify-center">
          {sp}
        </div>
      );
    });
  };
  return (
    <CSSTransition
      in={isModalOpen}
      timeout={300}
      classNames="modal"
      unmountOnExit
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-full absolute z-1"
        ></div>
        <div
          className={`bg-black w-[95%] mt-[4rem] sm:mt-0 z-[3] sm:w-[50%]  lg:w-1/4  md:ml-[15rem] border border-[rgb(44,46,51)] rounded shadow-lg transform translate-y-0 transition-transform ease-in duration-300`}
        >
          {loading ? (
            <div className="min-h-[456px] flex items-center justify-center">
              <LoadingSpinner loading={true} />
            </div>
          ) : (
            <>
              <div className="text-white text-lg p-5 border-b border-[rgb(44,46,51)] flex items-center justify-between w-full">
                <div className="flex flex-col -gap-1">
                  <p>{paramName}</p>
                  <p className="text-xs sm:text-sm text-second">{paramKey} ({dataType})</p>
                </div>
                <BiX
                  onClick={() => setIsModalOpen(false)}
                  className="cursor-pointer w-[1.5rem] h-[1.5rem] hover:scale-110 duration-300"
                  color="rgb(44,53,51)"
                />
              </div>

              <div className="text-white p-3 sm:p-5 border-b border-[rgb(44,46,51)] flex flex-col gap-3">
                <div className="flex flex-col gap-3">
                  <h2 className="text-base sm:text-lg">Value</h2>
                  <div className="px-5 text-sm sm:text-base flex flex-col gap-1">
                    {splitText(paramValue)}
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h2 className="text-base sm:text-lg">Configs</h2>
                  <div className="px-5 text-sm sm:text-base flex flex-col gap-1">
                    {configs?.map((config) => (
                      <div key={config.id} className="my-2 bg-transparent border border-second text-[#10CAF0] py-2 rounded flex items-center justify-center">
                        {config.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex gap-5 items-center justify-end w-full p-3 sm:p-5">
                <button
                  onClick={deleteConfigParam}
                  className="bg-[#FB4149] text-white hover:bg-red-700 duration-300 py-1 px-2 sm:px-3 rounded text-sm sm:text-base"
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default ConfigParamModal;
