import React, {useState, useEffect} from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import IpCard from "../IpCard/IpCard";
import IpModal from "../IpModal/IpModal";

const FingerPosts = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [fingerPrints, setFingerPrints] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFingerprintId, setModalFingerPrintId] = useState();
  const [postCounts, setPostCounts] = useState();


  const fetchFingerPrints = () => {
    setLoading(true);
    axios
      .get("/api/v1/complete/fingerprints", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setFingerPrints(response.data.data); 
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchFingerPrints();
  }, []);
  return     <>
  <TransitionGroup className="sm:p-5 relative w-full min-h-[calc(100vh-220px)]">
    {loading ? (
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <LoadingSpinner loading={true} />
      </div>
    ) : (
      <CSSTransition timeout={500} classNames="post">
        <div className="grid grid-cols-1 lg:grid-cols-2 pb-5">
          { Object.keys(fingerPrints).map((fingerPrint) => {

              return (
                <IpCard
                key={fingerPrint}
                openModal={openModal}
                setModalIp={setModalFingerPrintId}
                fingerPrint={fingerPrint}
                postCount = {fingerPrints[fingerPrint]}
                />
              );              
          })}
        </div>
        </CSSTransition>
    )}
  </TransitionGroup>
  <IpModal fingerPrint={modalFingerprintId} isOpen={isModalOpen} onClose={closeModal} isFingerPrint={true} />
  </>
};

export default FingerPosts;
