import React from "react";
import ReactLoading from "react-loading";

const LoadingSpinner = ({ loading, fetchingSubreddit }) => {
  if (loading) {
    return (
      <div className="flex flex-col justify-center !bg-transparent items-center h-full">
        <div className="rounded-md h-12 w-12 border-4 border-t-4 border-second animate-spin "></div>{" "}
        <p className="text-xl mt-4 text-second">
          {fetchingSubreddit &&
            (fetchingSubreddit === "finished"
              ? "Contents are eliminating"
              : `Fetching this subreddit:${fetchingSubreddit}`)}
        </p>
      </div>
    );
  }
  return null;
};

export default LoadingSpinner;

// <div className="flex flex-col justify-center !bg-transparent items-center h-full">
//   <p className="text-2xl mb-4 text-second">Loading...</p>
//   <ReactLoading type="spin" color="#6c7293" height={75} width={75} />
//   <p className="text-xl mb-4 text-second">
//     {fetchingSubreddit && (fetchingSubreddit === 'finished'
//       ? 'Contents are eliminating'
//       : `Fetching this subreddit:${fetchingSubreddit}`)}
//   </p>
// </div>
