import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./HomeOnUse.css";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import config from "../../../config";
import { BiCheckCircle, BiX, BiXCircle } from "react-icons/bi";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import SureModal from "../../CommonComponents/SureModal/SureModal";

const HomeOnUse = ({ data, setIsChanged }) => {
  const { auth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [isSureModalOpen, setIsSureModalOpen] = useState(false);
  const [postDetails, setPostDetails] = useState({
    title: "",
    text: "",
    author: "",
    onUse: "",
    completed: "",
    createdAt: "",
    postId: "",
    id: "",
    language: "",
    subReddit: "",
  });
  const [isSuccess, setIsSuccess] = useState(null);
  const [type, setType] = useState();


  const handleRestore = async() => {
    try {
      setLoading(true)
      const response = await axios.put(
        config.baseUrl + `api/v1/reddit/post/${postDetails.postId}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setIsSuccess(true); 
      setTimeout(() => {
        setIsChanged(true)
        setLoading(false)
      }, 2500);


    } catch (error) {
      setIsSuccess(false);
      setTimeout(() => {
        setIsChanged(true)
        setLoading(false)
      }, 2500);
    }
  };

  const handleRestoreAndDelete = async() => {
    try {
      setLoading(true)
      const responseOfRestore = await axios.put(
        config.baseUrl + `api/v1/reddit/post/${postDetails.postId}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      ); 
      const responseOfDelete = await axios.delete(
        config.baseUrl + `api/v1/reddit/post/${postDetails.postId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      )
      setIsSuccess(true); 
      setTimeout(() => {
        setIsChanged(true)
        setLoading(false)
      }, 2500);
    } catch (error) {
      setIsSuccess(false);
      setTimeout(() => {
        setIsChanged(true)
        setLoading(false)
      }, 2500);
    }
  };

  
  const handleComplete = async() => {
    setLoading(true)
    try {
        const response = await axios.put(config.baseUrl + `api/v1/reddit/post/${postDetails.postId}/complete`, {}, {
            headers: {
                Authorization: `Bearer ${auth.accessToken}`
            }
        }
        );
        setIsSuccess(true);
        setTimeout(() => {
          setIsChanged(true)
          setLoading(false)
        }, 5000);

    } catch (error) {
      setIsSuccess(false);
      setTimeout(() => {
        setIsChanged(true)
        setLoading(false)
      }, 5000);
    }
};

  

  const getPostDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        config.baseUrl + `api/v1/reddit/${data.content_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      const d = response.data.data;
      setPostDetails({
        title: d.title,
        text: d.text,
        author: d.author,
        onUse: d.on_use,
        completed: d.completed,
        createdAt: d.created_at,
        postId: d.post_id,
        id: d.id,
        language: d.language,
        subReddit: d.subreddit,
      });
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const calcTime = (completedTime) => {
    const now = new Date();
    const completedDate = new Date(completedTime);
    const timeDifference = now - completedDate;
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);

    if (secondsAgo < 60) {
      return `${secondsAgo} second${secondsAgo === 1 ? "" : "s"} ago`;
    } else if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    } else {
      return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
    }
  };
  return (
    <>
      <tr className="border-b border-b-[#2B2E33] text-xs sm:text-[14px]">
        <td className="whitespace-nowrap px-4 py-4">{data?.content_id}</td>
        <td className="whitespace-nowrap px-4 py-4">{data?.content_type.content_type_name}</td>
        <td className="whitespace-nowrap px-4 py-4">{data?.ip_address}</td>
        <td className="whitespace-nowrap px-4 py-4">
          {data?.finger_print?.slice(0, 15)}
        </td>
        <td className="whitespace-nowrap px-4 py-4">
          {calcTime(data?.on_use_at)}
        </td>
        <td
          className={`whitespace-nowrap px-4 py-4 ${
            data?.ready ? "text-left" : "text-center"
          }`}
        >
          {data?.ready ? calcTime(data.ready_date) : "-"}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-[#FFC633]">
            <div className="group relative">
            {data.status_message?.startsWith("ERROR") ? <>
            ERROR
            <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-second p-2 text-xs text-white group-hover:scale-100">
              {data.status_message}     
             </span>
            </>   : data.status_message}

            </div>
        </td>
        <td className="whitespace-nowrap px-4 py-4">
          <button
            onClick={() => {
              setIsModalOpen(true);
              getPostDetails();
            }}
            className="border rounded px-3 py-1 text-[#00D25A] font-[400] border-[#00D25A]"
          >
            Details
          </button>
        </td>
      </tr>

      <CSSTransition
        in={isModalOpen}
        timeout={300}
        classNames="modal"
        unmountOnExit
      >
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div onClick={()=>setIsModalOpen(false)} className="w-full h-full absolute z-1">
            </div>

              <div className={`bg-green-200 scale-0 absolute text-sm sm:text-base sm:left-1/2 top-[7rem] z-[99] sm:top-5 text-green-700 border-l-4 rounded rounded-l-none border-green-700 p-4 flex items-center duration-300 justify-center gap-8 ${isSuccess === true && 'scale-100'}`}>
                <BiCheckCircle className="w-[2rem] h-[2rem]"/>
               <p>Process completed.</p> 
            </div>
            <div className={`bg-red-200 absolute scale-0 text-sm sm:text-base sm:left-1/2 top-[7rem] z-[99] sm:top-5 text-red-700 border-l-4 rounded rounded-l-none border-red-700 p-4 flex items-center duration-300 justify-center gap-8 ${isSuccess === false && 'scale-100'}`}>
            <BiXCircle className="w-[2rem] h-[2rem]"/>
               <p>Process failed.</p> 
          </div> 

          <div
            className={`bg-black w-[95%] mt-[4rem] sm:mt-0 z-[3]  md:w-1/2 md:ml-[15rem] border border-[rgb(44,46,51)] rounded shadow-lg transform translate-y-0 transition-transform ease-in duration-300`}
          >
            {loading ? (
              <div className="min-h-[456px] flex items-center justify-center">
                <LoadingSpinner loading={true} />
              </div>
            ) : (
              <>
                <div className="text-white text-lg p-5 border-b border-[rgb(44,46,51)] flex items-center justify-between w-full">
                  <div className="flex items-center justify-center gap-5">
                    <div className="flex items-center justify-center gap-2 flex-wrap">
                      {data.content_id && (
                        <div className="group relative">
                          <span className="rounded border text-xs  px-3 p-1 border-[#0090E6] text-[#0090E6] font-semibold">
                            {data.content_id}
                          </span>
                          <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-main p-2 text-xs text-white group-hover:scale-100">
                            Content Id
                          </span>
                        </div>
                      )}
                      {postDetails.onUse && (
                        <div className="group relative">
                          <span className="rounded border text-xs  px-3 p-1 border-[#E89D0E] text-[#E89D0E] font-semibold">
                            onUse
                          </span>
                          <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-main p-2 text-xs text-white group-hover:scale-100">
                            On Use
                          </span>
                        </div>
                      )}

                      {postDetails.completed === true && (
                        <div className="group relative">
                          <span className="rounded border text-xs  px-3 p-1 border-[#00D25A] text-[#00D25A] font-semibold">
                            {postDetails.completed && "completed"}
                          </span>
                          <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-main p-2 text-xs text-white group-hover:scale-100">
                            Completed
                          </span>
                        </div>
                      )}
                      {postDetails.language && (
                        <div className="group relative">
                          <span className="rounded border text-xs  px-3 p-1 border-[#8F5FE7] text-[#8F5FE7] font-semibold">
                            {postDetails.language}
                          </span>
                          <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-main p-2 text-xs text-white group-hover:scale-100">
                            Language
                          </span>
                        </div>
                      )}
                      {postDetails.postId && (
                        <div className="group relative">
                          <span className="rounded border text-xs  px-3 p-1 border-[#18C1E5] text-[#18C1E5] font-semibold">
                            {postDetails.postId}
                          </span>
                          <span class="absolute -top-8 duration-300 -right-12 scale-0 rounded bg-main p-2 text-xs text-white group-hover:scale-100">
                            Post Id
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <BiX
                    onClick={() => setIsModalOpen(false)}
                    className="cursor-pointer w-[1.5rem] h-[1.5rem] hover:scale-110 duration-300"
                    color="rgb(44,53,51)"
                  />
                </div>
                <div className="text-white p-3 sm:p-5 border-b border-[rgb(44,46,51)] flex flex-col gap-3">
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-xs sm:text-base sm:items-center ">
                    <label className="justify-start">Title </label>
                    <input
                      className="bg-[#2A3038] px-3 py-2 rounded text-xs sm:text-base text-white w-full"
                      value={postDetails.title}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-[4.8rem] items-start text-xs sm:text-base sm:items-center ">
                    <label className="justify-start">Author </label>
                    <input
                      className="bg-[#2A3038] px-3 py-2 rounded text-xs sm:text-base text-white w-full"
                      value={postDetails.author}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-[3.3rem] items-start text-xs sm:text-base sm:items-center ">
                    <label className="justify-start">CreatedAt</label>
                    <input
                      className="bg-[#2A3038] px-3 py-2 rounded text-xs sm:text-base text-white w-full"
                      value={postDetails.createdAt}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-14 items-start text-xs sm:text-base sm:items-center ">
                    <label className="justify-start">Subreddit</label>
                    <input
                      className="bg-[#2A3038] px-3 py-2 rounded text-xs sm:text-base text-white w-full"
                      value={postDetails.subReddit}
                      disabled
                    />
                  </div>
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-[6.2rem] items-start text-xs sm:text-base sm:items-center ">
                    <label className="justify-start">Text</label>
                    <textarea
                      className="bg-[#2A3038] h-16 sm:h-24 resize-none px-3 py-2 rounded text-xs sm:text-base text-white w-full"
                      value={postDetails.text}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex gap-5 items-center justify-end w-full p-3 sm:p-5">
                  <button
                    onClick={()=>{
                      setIsSureModalOpen(true)
                      setType('restore')
                    }}
                    className="bg-white text-black hover:bg-slate-200 duration-300 py-1 px-2 sm:px-3 rounded text-xs sm:text-base"
                  >
                    Restore
                  </button>
                  <button
                    onClick={()=>{
                      setIsSureModalOpen(true)
                      setType('restore&delete')
                    }}
                    className="bg-red-600 text-white hover:bg-red-700 duration-300 py-1 px-1 sm:px-3 rounded text-xs sm:text-base"
                  >
                    Restore & Delete
                  </button>
                  <button
                    onClick={()=>{
                      setIsSureModalOpen(true) 
                      setType('complete')
                    }}
                    className="bg-[#00D25A] hover:bg-green-600 duration-300 text-white py-1 px-2 sm:px-3 rounded text-xs sm:text-base"
                  >
                    Complete
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </CSSTransition>
      <SureModal handleRestore={handleRestore} handleRestoreAndDelete={handleRestoreAndDelete} handleComplete={handleComplete} type={type} isSureModalOpen={isSureModalOpen} setIsSureModalOpen={setIsSureModalOpen}/>
    </>
  );
};

export default HomeOnUse;
