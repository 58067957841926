import React from 'react'

const Database = () => {
  return (
    <div>
      Database
    </div>
  )
}

export default Database
