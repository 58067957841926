import React, { useRef, useState } from "react";
import { BiXCircle, BiInfoCircle, BiLoaderCircle } from "react-icons/bi";
import { useEffect } from "react";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
const AccountInfoModal = ({
  isOpen,
  setIsOpen,
  title,
  id,
  email,
  password,
  type,
  sessionId,
  setIsChanged,
}) => {
  const { auth } = useAuth();
  const modalRef = useRef(null);
  const [loading, setLoading] = useState();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(()=>{
    setIsChanged(false)
  }, [])
  useOutsideAlerter(modalRef);

  const handleDelete = (e) => {
    e.preventDefault();
    setLoading(true);
    if (type === "YOUTUBE") {
      axios
        .delete(`/api/v1/account/youtube/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);
          setIsChanged(true);
        })
        .catch((err) => {
          setLoading(false);
          setIsChanged(false);
        });
    } else {
      axios
        .delete(`/api/v1/account/tiktok/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);
          setIsChanged(true);
        })
        .catch((err) => {
          setLoading(false);
          setIsChanged(false);
        });
    }
  };

  return (
    <div
      ref={modalRef}
      className={`${
        isOpen ? "absolute animate-fadeIn0" : "hidden animate-fadeOut"
      } duration-300 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2  max-w-screen max-h-screen bg-main shadow-3xl rounded flex p-5 justify-start flex-col gap-5 z-[99]`}
    >
      <BiXCircle
        onClick={() => setIsOpen(false)}
        className="absolute right-5 top-6 w-[1.5rem] h-[1.5rem] cursor-pointer text-[#FB4149] hover:text-red-600 duration-200 hover:scale-110"
      />
      <h2 className="text-lg mb-4">{title}</h2>
      {type === "YOUTUBE" ? (
        <>
          {" "}
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
            <label className="justify-start">Email </label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={email}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-16 items-start text-sm sm:text-base sm:items-center ">
            <label>Password </label>
            <input
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              value={password}
              disabled
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
          <label className="justify-start">Session Id </label>
          <input
            className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
            value={sessionId}
            disabled
          />
        </div>
      )}
      <div className="buttons w-full flex justify-end mt-4">
        <button
          onClick={handleDelete}
          disabled={loading ? true : false}
          className="bg-[#FB4149] w-[9rem] h-8 px-2 py-1 rounded text-sm sm:text-base hover:bg-red-700 duration-300 flex items-center justify-center"
        >
          {
          loading ? (
            <BiLoaderCircle className="w-[1.5rem] h-[1.5rem] animate-spin" />
          ) : (
            "Delete Account"
          )}
        </button>
      </div>
    </div>
  );
};

export default AccountInfoModal;
