import React, { useEffect, useState } from 'react'

const AddParamCheckbox = ({ formValue, setFormValue, disabled }) => {
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = () => {
    setChecked(!checked);
    setFormValue({...formValue, value: !checked})
  };

  useEffect(()=>{
    setFormValue({...formValue, value: false})
  }, [])
  return (
    <label className="inline-flex items-center">
    <input disabled={disabled} onChange={handleCheckboxChange} type='checkbox' className='h-5 w-5 text-red-200 disabled:accent-[#111316] accent-[#2A3038]' />
    <span className="ml-2 text-white text-sm">{!checked ? "False" : "True"}</span>
    </label>    
  )
}

export default AddParamCheckbox
