import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Vps = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    if(location.pathname!=="/vps/config-param" && location.pathname!== "/vps/accounts"){
        navigate('/vps/config');
    }
  }, [])
  return (
    <div className='w-full h-full'>
      <Outlet/>
    </div>
  )
}

export default Vps
