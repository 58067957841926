import OnUseTable from "../../components/HomeComponents/OnUseTable/OnUseTable";
import TodayTable from "../../components/HomeComponents/TodayTable/TodayTable";
import useTodayPost from "../../hooks/useTodayPost";
import LoadingSpinner from "../../components/CommonComponents/LoadingSpinner/LoadingSpinner";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from "axios";
import config from "../../config";
import { useEffect, useState } from "react";
import HomeAccountCards from "../../components/HomeComponents/HomeAccountCards/HomeAccountCards";
ChartJS.register(ArcElement, Tooltip, Legend);

const Home = () => {
  const { posts, postLoading } = useTodayPost();
  const dailyData = {
    target: 16,
    actual: posts.length > 16 ? 16 : posts.length,
  };
  const { target, actual } = dailyData;
  const [isOnUseChanged, setIsOnUseChanged] = useState(false);



  const data = {
    labels: ['Completed', 'Remaining'],
    datasets: [
      {
        data: [actual, target - actual],
        backgroundColor: [
          '#1D302B',
          '#473D2B',
        ],
        borderColor: [
          '#00D25A',
          '#FECE56',
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        fullWidth:true,
        labels: {
          color: '#6C7291',
          usePointStyle: true,
          font: {
            size: 12,
          },
          padding: 9,
        },  
        fontFamily: 'Times New Roman'
      },

    },
  };

  return (
    <div className="min-h-screen w-full p-5">
      <div>
        <HomeAccountCards/>
      </div>
      <div className="grid grid-rows-2 xl:grid-rows-1 xl:grid-cols-4 px-5 gap-6 mt-8">
        <div className="col-span-4 xl:col-span-1 relative bg-main rounded flex flex-col items-start justify-start h-full w-full overflow-hidden p-5">
          
          <h2 className="text-base sm:text-lg">Total Video Goal</h2>
          <div
            className="flex px-5 justify-center items-center h-full"
            style={{
              width: "100%",
              whiteSpace: "nowrap", 
            }}
          >
            <div className="h-[70%] lg:h-full w-full">
            {
              !postLoading  ?
              <Doughnut data={data} options={options} />            
              :
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <LoadingSpinner loading={true} />
            </div>
            }
            </div>


          </div>
        </div>
        <div className="col-span-4 xl:col-span-3">
          <TodayTable isOnUseChanged={isOnUseChanged} setIsOnUseChanged={setIsOnUseChanged} />
        </div>
      </div>
      <div className="grid px-5 mt-8">
        <OnUseTable setIsOnUseChanged={setIsOnUseChanged}/>
      </div>
    </div>
  );
};

export default Home;
