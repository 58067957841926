import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './SureModal.css';
import { BiX } from 'react-icons/bi';

const SureModal = ({isSureModalOpen,setIsSureModalOpen, type, handleRestore, handleRestoreAndDelete, handleComplete}) => {
  return (
    <CSSTransition
    in={isSureModalOpen}
    timeout={300}
    classNames="modal"
    unmountOnExit
  >
    <div className="fixed inset-0 flex items-center justify-center z-50">
        <div onClick={()=>setIsSureModalOpen(false)} className="w-full h-full absolute z-1">
        </div>
      <div
        className={`bg-black w-[70%] mt-[4rem] sm:mt-0 z-[3]  md:w-[40%] md:ml-[15rem] border border-[rgb(44,46,51)] rounded shadow-lg transform translate-y-0 transition-transform ease-in duration-300`}
      >
              <div className="text-white text-base sm:text-lg p-3 sm:p-5 border-b border-[rgb(44,46,51)] flex items-center justify-between w-full">
                <h2>INFO</h2>
                    <BiX
                    onClick={() => setIsSureModalOpen(false)}
                    className="cursor-pointer w-[1.5rem] h-[1.5rem] hover:scale-110 duration-300"
                    color="rgb(44,53,51)"
                  />
              </div>
              <div className="text-white p-3 sm:p-5 border-b border-[rgb(44,46,51)] flex flex-col gap-3 text-sm sm:text-base">
                Are you sure ?
              </div>
              <div className="flex gap-5 items-center justify-end w-full p-3 sm:p-5">
              <button
                    onClick={()=>{setIsSureModalOpen(false)}}
                    className="bg-white w-16 text-black hover:bg-slate-200 duration-300 py-1 px-2 sm:px-3 rounded text-sm sm:text-base"
                  >
                    No
                  </button>
                  <button
                    onClick={()=>{
                        if(type==='complete'){
                            handleComplete()
                        }
                        else if (type === 'restore&delete'){
                            handleRestoreAndDelete()
                        }
                        else {
                            handleRestore()
                        }
                        setIsSureModalOpen(false)
                    }}
                    className="bg-[#00D25A] w-16 hover:bg-green-600 duration-300 text-white py-1 px-2 sm:px-3 rounded text-sm sm:text-base"
                  >
                    Yes
                  </button>
              </div>
      </div>
    </div>
  </CSSTransition>
  )
}

export default SureModal
