import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import { BiMouse, BiChevronDown, BiChevronUp } from "react-icons/bi";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import HomeToday from "../HomeToday/HomeToday";
import useTodayPost from "../../../hooks/useTodayPost";
import useAuth from "../../../hooks/useAuth";
import TodayPlaceholder from "../TodayPlaceholder/TodayPlaceholder";
import NothingGif from "../../../assets/gifs/nothing.gif";

const TodayTable = ({isOnUseChanged, setIsOnUseChanged}) => {
  const [scrolled, setScrolled] = useState(false);
  const { posts, setPosts, postLoading, setPostLoading } = useTodayPost();
  const { auth } = useAuth();

  const fetchPosts = () => {
    setPostLoading(true);
    axios
      .get(config.baseUrl + "api/v1/complete/today", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setPosts(response.data.data);
        setPostLoading(false);
        setIsOnUseChanged(false)
      })
      .catch((err) => {
        console.log(err);
        setPostLoading(false);
      });
  };

  useEffect(() => {
    if (posts.length < 1) {
      fetchPosts();
    }
  }, []);

  useEffect(() => {
    if (isOnUseChanged === true) {
      fetchPosts();
    }
  }, [isOnUseChanged]);

  return (
    <div className="w-full bg-main p-5 flex flex-col gap-2 rounded relative overflow-hidden">
      <h2 className="text-base sm:text-lg">Today Completed Contents</h2>
      {posts.length > 4 && !scrolled && (
        <div className="absolute right-6 top-1 flex flex-col items-center justify-center gap-1 ">
          <BiChevronUp className="w-[1.5rem] h-[1.5rem] animate-blink text-white -mb-2" />
          <BiMouse className="w-[1.5rem] h-[1.5rem] animate-blink text-white" />
          <BiChevronDown className="w-[1.5rem] h-[1.5rem] animate-blink text-white -mt-2" />
        </div>
      )}

      <div
        onScroll={() => setScrolled(true)}
        className="!h-[300px] !overflow-y-auto scrollbar-hide"
      >
        {postLoading ? (
          <>
            <TodayPlaceholder />
            <TodayPlaceholder />
            <TodayPlaceholder />
            <TodayPlaceholder />
          </>
        ) : posts.length < 1 ? (
          <div className="w-full h-full flex justify-center items-center ">
          <img src={NothingGif} className="w-full h-full" alt="loading" />
          </div>
        ) : (
          posts.map((post, index) => (
            <HomeToday
              key={post.id}
              contentId={post.content_id}
              ipAddress={post.ip_address}
              fingerPrint={post.finger_print}
              completedTime={post.completed_at}
              bg={"#FC471E"}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TodayTable;
