import React from "react";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import { useEffect } from "react";
import IpCard from "../IpCard/IpCard";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import IpModal from "../IpModal/IpModal";

const IpCompletedPosts = () => {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [ips, setIps] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIp, setModalIp] = useState();
  const [postCounts, setPostCounts] = useState();

  const fetchIps = () => {
    setLoading(true);
    axios
      .get("/api/v1/complete/ips", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setIps(response.data.data); 
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchIps();
  }, []);
  return (
    <>
    <TransitionGroup className="sm:p-5 relative w-full min-h-[calc(100vh-220px)]">
      {loading ? (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <LoadingSpinner loading={true} />
        </div>
      ) : (
        <CSSTransition timeout={500} classNames="post">
          <div className="grid grid-cols-1 lg:grid-cols-2 pb-5">
            { Object.keys(ips).map((ip) => {

                return (
                  <IpCard
                  key={ip}
                  openModal={openModal}
                  setModalIp={setModalIp}
                  ip={ip}
                  postCount = {ips[ip]}
                  />
                );              
            })}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
    <IpModal ip={modalIp} isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default IpCompletedPosts;
