import { createContext, useState } from "react";

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState([]);
  const [allConfigParams, setAllConfigParams] = useState([]);
  const [paramsArray, setParamsArray] = useState([]);
  const [keysArray, setKeysArray] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);


  return (
    <ConfigContext.Provider value={{ configs, setConfigs, allConfigParams, setAllConfigParams, paramsArray, setParamsArray, keysArray, setKeysArray, dataTypes, setDataTypes, contentTypes, setContentTypes}}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
