const ProgressBar = ({ percentage, existAmount, finalAmount }) => {
    if (existAmount == finalAmount) {
        percentage = 100;
    }
    return (
    <div className="rounded-lg mt-4 flex justify-between p-2 bg-main">
      <p className="text-white mr-2">{existAmount}</p>
      <div className="bg-second rounded-lg w-full">
        <div
          className={`bg-blue-500 h-6 rounded-lg text-end text-white text-sm`}
          style={{ width: `${percentage}%` }}
        >
          {percentage.toFixed(1)}%
        </div>
      </div>
      <p className="text-white ml-2">{finalAmount}</p>
    </div>
  )}

export default ProgressBar;