import { createContext, useState, useEffect } from "react";

const PostContext = createContext({});

export const PostProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  return (
    <PostContext.Provider value={{ posts, setPosts, postLoading, setPostLoading }}>
      {children}
    </PostContext.Provider>
  );
};

export default PostContext;
