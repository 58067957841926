import React, { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const LogoutModal = ({ loading, isOpen, onConfirm, onCancel }) => {
  return (
    <div
      className={`fixed inset-0 bg-transparent flex items-center justify-center text-white
        ${
          isOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }
        `}
    >
      <div
        className={`bg-main px-8 py-6 rounded-lg shadow-md ${
          isOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        } transition-opacity duration-300`}
      >
        {loading ? (
          <div className="w-full h-full">
              <h2 className="text-lg mb-4">You're logging out. Please wait...</h2>
              <LoadingSpinner loading={true} />
            </div>
        ) : (
          <>
            <h2 className="text-lg mb-4">Are you sure you want to sign out?</h2>
            <div className="flex justify-end mt-5">
              <button
                className="mr-2 px-3 py-2 bg-second text-white rounded hover:bg-[#4b5073] focus:outline-none"
                onClick={onConfirm}
              >
                Sign Out
              </button>
              <button
                className="px-3 py-2 bg-white text-second rounded hover:bg-gray-400 focus:outline-none"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LogoutModal;
