import React, { useEffect } from "react";

const AddParamDate = ({
  value,
  onChange,
  disabled,
  formValue,
  setFormValue,
}) => {
  useEffect(() => {
    setFormValue({ ...formValue, value: "" });
  }, []);
  return (
    <input
      name="value"
      className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-full transition disabled:opacity-40 border-0 focus:outline-[#10CAF0] outline-none"
      type="date"
      value={value} 
      onChange={onChange} 
      disabled={disabled}
    />
  );
};

export default AddParamDate;
