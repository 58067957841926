import React from 'react'

const TodayPlaceholder = () => {
  return (
    <div className="flex flex-row justify-between py-3  border-b-[1px] border-b-[#2B2E33]">
      <div className="flex justify-center items-center gap-4 text-base">
        <div className={`p-2 flex items-center justify-center bg-[#515559] text-[#515559] animate-blink`}>
          <div className="w-[1.2rem] h-[1.2rem] sm:w-[2rem] sm:h-[2rem]" />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs sm:text-[16px] bg-[#515559] text-[#515559] animate-blink">Content Id: 2046</p>
          <p className="text-xs sm:text-[14px] bg-[#515559] text-[#515559] animate-blink">81.214.160.187</p>
        </div>
      </div>

      <div className="flex flex-col justify-center text-xs gap-3 sm:text-[14px] text-second">
        <p className='bg-[#515559] text-[#515559] animate-blink'>1 hour ago</p>
        <p className='bg-[#515559] text-[#515559] animate-blink'>59a9f2364dece5f</p>
      </div>
    </div>
  )
}

export default TodayPlaceholder
