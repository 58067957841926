import React, { useRef, useEffect, useState } from "react";
import { BiCheckCircle, BiInfoCircle, BiLoaderCircle, BiXCircle } from "react-icons/bi";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
const AddAccountModal = ({ isOpen, setIsOpen, type, setIsChanged }) => {
  const [ytFormValue, setYtFormValue] = useState({
    title: "",
    email: "",
    password: "",
  });
  const [tiktokFormValue, setTiktokFormValue] = useState({
    title: "",
    sessionId: "",
  });
  const [loading, setLoading] = useState();
  const { auth } = useAuth();
  const modalRef = useRef(null);
  const [errMsg, setErrMsg] = useState("");
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    setIsChanged(false);
  }, []);

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isInputValid = (inputValue, minLength) => {
    return inputValue.length >= minLength;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setYtFormValue({
      ...ytFormValue,
      [name]: value,
    });
  };

  const handleTiktokInputChange = (e) => {
    const { name, value } = e.target;
    setTiktokFormValue({
      ...tiktokFormValue,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (type === "YOUTUBE") {
      if (!isEmailValid(ytFormValue.email)) {
        setErrMsg("Please enter a valid email address");
        setYtFormValue({
          ...ytFormValue,
          email: "",
        });
        return;
      } else if (!isInputValid(ytFormValue.title, 5)) {
        setErrMsg("Please enter a longer title");
        setYtFormValue({
          ...ytFormValue,
          title: "",
        });
        return;
      } else if (!isInputValid(ytFormValue.password, 5)) {
        setErrMsg("Please enter a longer password");
        setYtFormValue({
          ...ytFormValue,
          password: "",
        });
        return;
      }
    }
    else if (type === "TIKTOK") {
      if (!isInputValid(tiktokFormValue.title, 5)) {
        setErrMsg("Please enter a longer title");
        setTiktokFormValue({
          ...tiktokFormValue,
          title: "",
        });
        return;
      } else if (!isInputValid(tiktokFormValue.sessionId, 15)) {
        setErrMsg("Please enter a longer Session Id");
        setTiktokFormValue({
          ...tiktokFormValue,
          sessionId: "",
        });
        return;
      }
    }
    setLoading(true);
    if (type === "YOUTUBE") {
      axios
        .post(
          "/api/v1/account/youtube",
          {
            title: ytFormValue.title,
            email: ytFormValue.email,
            password: ytFormValue.password,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          setLoading(false);
          setYtFormValue({
            title: "",
            email: "",
            password: "",
          });
          setIsChanged(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setYtFormValue({
            title: "",
            email: "",
            password: "",
          });
          setIsChanged(true);
        });
    } else {
      axios
        .post(
          "/api/v1/account/tiktok",
          {
            title: tiktokFormValue.title,
            session_id: tiktokFormValue.sessionId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          setLoading(false);
          setTiktokFormValue({
            title: "",
            sessionId: "",
          });
          setIsChanged(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setTiktokFormValue({
            title: "",
            sessionId: "",
          });
          setIsChanged(true);
        });
    }
  };

  useOutsideAlerter(modalRef);
  return (
    <div
      ref={modalRef}
      className={`${
        isOpen ? "absolute animate-fadeIn0" : "hidden animate-fadeOut"
      } duration-300 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 max-w-screen max-h-screen bg-main shadow-3xl rounded flex p-5 justify-start flex-col gap-5 z-[99]`}
    >
      <BiXCircle
        onClick={() => setIsOpen(false)}
        className="absolute right-5 top-[1.4rem] w-[1.5rem] h-[1.5rem] cursor-pointer text-[#FB4149] hover:text-red-600 duration-200 hover:scale-110"
      />
      <h2 className="text-base sm:text-lg ">Add New {type} Account</h2>
      <div className={`flex text-[#FFAB00] flex-row gap-3 items-center transform transition-all ease-in-out duration-300 ${errMsg ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-2rem] h-0 overflow-hidden'}`}>
        <BiInfoCircle className="w-[1.5rem] h-[1.5rem]"/>
        {errMsg}
      </div>{" "}
      {type === "YOUTUBE" ? (
        <>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
            <label className="justify-start">Title </label>
            <input
              name="title"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              placeholder="Title"
              value={ytFormValue.title}
              onChange={handleInputChange}
              required
              disabled={loading ? true : false}
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-[5.5rem] items-start text-sm sm:text-base sm:items-center ">
            <label>Email </label>
            <input
              name="email"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              placeholder="Email"
              type="email"
              value={ytFormValue.email}
              onChange={handleInputChange}
              required
              disabled={loading ? true : false}
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-[3.5rem] items-start text-sm sm:text-base sm:items-center ">
            <label>Password </label>
            <input
              name="password"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              placeholder="Password"
              value={ytFormValue.password}
              onChange={handleInputChange}
              required
              disabled={loading ? true : false}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
            <label className="justify-start">Title </label>
            <input
              name="title"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              placeholder="Title"
              value={tiktokFormValue.title}
              onChange={handleTiktokInputChange}
              required
              disabled={loading ? true : false}
            />
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-[3.3rem] items-start text-sm sm:text-base sm:items-center ">
            <label>Session Id </label>
            <input
              name="sessionId"
              className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64"
              placeholder="Session Id"
              type="email"
              value={tiktokFormValue.sessionId}
              onChange={handleTiktokInputChange}
              required
              disabled={loading ? true : false}
            />
          </div>
        </>
      )}
      <div className="flex justify-end w-full mt-4 text-sm sm:text-base">
        <button
          onClick={handleSave}
          type="submit"
          className="bg-[#0090E6] hover:bg-blue-400 w-[5rem] h-8 flex items-center justify-center duration-200 px-3 py-1 rounded"
          disabled={loading ? true : false}
        >
          {loading ? (
            <BiLoaderCircle className="w-[1.5rem] h-[1.5rem] animate-spin" />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddAccountModal;
