import { createContext, useState } from "react";

const AccountContext = createContext({});

export const AccountProvider = ({ children }) => {
    const [ytAccounts, setYtAccounts] = useState([]);
    const [tiktokAccounts, setTiktokAccounts] = useState([]);
    return (
        <AccountContext.Provider value={{ ytAccounts, tiktokAccounts, setYtAccounts, setTiktokAccounts }}>
            {children}
        </AccountContext.Provider>
    )
}

export default AccountContext;
