import React from "react";
import { useTransition, animated } from "react-spring";


const PostCard = ({ props,post, removePost, addPost, ifExistBgColor, openModal }) => (
    <animated.div
    style={props}
      className="inset-0 flex items-center justify-center"
    >
      <div
        className={`w-full p-4 m-4 rounded-2xl`}
        style={{ backgroundColor: ifExistBgColor(post.post_id) }}
      >
        <div className=" grid grid-cols-3 gap-4">
          <div className='col-span-2 justify-around'>
            <h2 className=''>Post Id:{post.post_id}</h2>
            <h2 className=''>Title:{post.title}</h2>
          </div>
          <button
            onClick={() => {
              removePost(post.post_id);
            }}
            className="bg-orange-500  rounded-xl h-[60px] text-white px-2 py-2 mx-4"
          >
            Delete
          </button>
          <p className='col-span-2'>Text:{post.text?.toString().substring(0, 80)}...</p>
          <button
            onClick={() => {
              addPost(post.post_id);
            }}
            disabled={ifExistBgColor(post.post_id) === '#F87171'}
            className="bg-[#0000ff] rounded-xl h-[60px] text-white px-2 py-2 mx-4"
          >
            {ifExistBgColor(post.post_id) === '#F87171' ? 'Already Exist' : 'Add'}
          </button>
          <button
            onClick={() => openModal(post)}
            className="bg-second col-span-2 rounded-xl md:max-h-[40px] text-white px-2 py-2 mx-4"
          >
            Details
          </button>
        </div>
      </div>
    </animated.div>
  );

export default PostCard;