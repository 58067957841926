import React, { useState, useEffect } from 'react';
import AccountCard from '../../../components/AccountComponents/AccountCard/AccountCard';
import AddAccountCard from '../../../components/AccountComponents/AddAccountCard/AddAccountCard';
import LoadingSpinner from '../../../components/CommonComponents/LoadingSpinner/LoadingSpinner';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios';
import useAccount from '../../../hooks/useAccount';

const TiktokAccount = () => {
    const { auth } = useAuth();
    const { tiktokAccounts, setTiktokAccounts } = useAccount();
    const [loading, setLoading] = useState();
    const [isChanged, setIsChanged] = useState();
    const [isInfoChanged, setIsInfoChanged] = useState();

    const getAllTiktokAccounts = () => {
      setLoading(true)
      axios
        .get("api/v1/account/tiktok", {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        })
        .then((response) => {
          setTiktokAccounts(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };
  
    useEffect(()=>{
      tiktokAccounts?.length < 1 && getAllTiktokAccounts();
    }, [])

    useEffect(()=>{
      isChanged && getAllTiktokAccounts();
    }, [isChanged])

    useEffect(()=>{
      isInfoChanged && getAllTiktokAccounts();
    }, [isInfoChanged])
    
    return (
        <div className='w-full h-full p-5'>
            {
                loading ? <div className='h-screen'>
                    <LoadingSpinner loading={true}/> 
                </div>:
                <div className='grid md:grid-cols-2'>
                {
                tiktokAccounts?.map((account)=>(
                    <AccountCard setIsInfoChanged={setIsInfoChanged} type={account.type} key={account.id} id={account.id} sessionId={account.session_id} title={account.title} />
                ))
                }
                <AddAccountCard setIsChanged={setIsChanged} type="TIKTOK"/>
                </div>
            }
        </div>
      )
}

export default TiktokAccount
