import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import LoadingSpinner from "../../../components/CommonComponents/LoadingSpinner/LoadingSpinner";
import AccountCard from "../../../components/AccountComponents/AccountCard/AccountCard";
import AddAccountCard from "../../../components/AccountComponents/AddAccountCard/AddAccountCard";
import VpsAccountCard from "../../../components/ConfigComponents/VpsAccountCard/VpsAccountCard";

const VpsAccounts = () => {
  const { auth } = useAuth();
  const [accounts, setAccounts] = useState();
  const [loading, setLoading] = useState();
  const [isChanged, setIsChanged] = useState();
  const [isInfoChanged, setIsInfoChanged] = useState();

  const getAllVpsAccounts = () => {
    setLoading(true);
    axios
      .get("api/v1/role/vps", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setAccounts(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const maintenance = false;


  useEffect(()=>{
    getAllVpsAccounts();
  }, [])
  return (
    <div className="w-full h-full p-5">
      {loading ? (
        <div className="h-screen">
          <LoadingSpinner loading={true} />
        </div>
      ) : (
        maintenance ? <div>
        This page is not available right now.
        </div> :
        <div className="grid md:grid-cols-2">
          {accounts?.map((account) => (
              <VpsAccountCard
              key={account.id}
              id={account.id}
              firstName={account.firstname}
              email={account.email}
              config = {account.config}

              />
          ))}
        </div>
      )}
    </div>
  );
};

export default VpsAccounts;
