import React, { useEffect } from 'react';
import gapi from 'gapi-client';


const Anlaytics = () => {
  const YOUR_CLIENT_ID = '203562396711-vttn9q23urcdsevjng1oa2bi4g36sdi9.apps.googleusercontent.com'; // Buraya kendi istemci kimliğinizi eklemelisiniz

  useEffect(() => {
    const loadGAPI = () => {
      gapi.load('client:auth2', () => {
        gapi.auth2.init({ client_id: YOUR_CLIENT_ID });
      });
    };
    loadGAPI();
  }, []);

  const authenticate = () => {
    return gapi.auth2.getAuthInstance()
      .signIn({ scope: 'https://www.googleapis.com/auth/yt-analytics.readonly' })
      .then(
        () => console.log('Sign-in successful'),
        (err) => console.error('Error signing in', err)
      );
  };

  const loadClient = () => {
    return gapi.client
      .load('https://youtubeanalytics.googleapis.com/$discovery/rest?version=v2')
      .then(
        () => console.log('GAPI client loaded for API'),
        (err) => console.error('Error loading GAPI client for API', err)
      );
  };

  const execute = () => {
    return gapi.client.youtubeAnalytics.reports
      .query({
        ids: 'channel==MINE',
        startDate: '2017-01-01',
        endDate: '2017-12-31',
        metrics:
          'views,estimatedMinutesWatched,averageViewDuration,averageViewPercentage,subscribersGained',
        dimensions: 'day',
        sort: 'day',
      })
      .then(
        (response) => {
          console.log('Response', response);
        },
        (err) => console.error('Execute error', err)
      );
  };

  return (
    <div>
      <button onClick={authenticate}>authorize and load</button> <br/>
      <button onClick={loadClient}>load client</button> <br/>
      <button onClick={execute}>execute</button>
    </div>
  );
};

export default Anlaytics;
