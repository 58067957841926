import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "../../../api/axios";
import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import CompletedPostCard from "../../HomeComponents/CompletedPostCard/CompletedPostCard";

const AllCompletedPosts = ({ openModal, setModalContentId }) => {
  const { auth } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loadedPostCount, setLoadedPostCount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [arrayLength, setArrayLength] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const btnRef = useRef();

  const scrollToBottom = () => {
    btnRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const fetchMorePosts = () => {
    setDisabled(true);
    axios
      .get(`/api/v1/complete/page/?index=${loadedPostCount}&limit=9`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.data.length === arrayLength) {
          setErrMsg("You can't load more data. That's all");
          return;
        }
        setArrayLength(response.data.data.length);
        setPosts((prevPosts) => [...prevPosts, ...response.data.data]);
        setLoadedPostCount((prevCount) => prevCount + 1);
        setDisabled(false);
        setTimeout(() => {
          scrollToBottom();
        }, 50);
      })
      .catch((error) => {
        console.error("An error occurred: ", error);
        setDisabled(false);
      });
  };

  useEffect(() => {
    fetchMorePosts();
  }, []);

  return (
    <div className="pb-5">
      <>
        <TransitionGroup className="grid grid-cols-1 lg:grid-cols-2 xl:p-5">
          {posts.map((post) => (
            <CSSTransition key={post.id} timeout={500} classNames="post">
              <CompletedPostCard
                content_id={post.content_id}
                content_type={post.content_type}
                ip_address={post.ip_address}
                finger_print={post.finger_print}
                completed_at={post.completed_at}
                on_use_at={post.on_use_at}
                ready_at={post.ready_at}
                openModal={openModal}
                setModalContentId={setModalContentId}
              />
            </CSSTransition>
          ))}
          </TransitionGroup>

            <p
              className={`text-center sm:text-2xl text-second mb-5 transition-opacity ${
                errMsg ? "opacity-100" : "opacity-0"
              }`}
            >
              {errMsg && errMsg}
            </p>
        <div className="flex items-center justify-center">
          <button
            ref={btnRef}
            className={`p-3 px-5 text-sm sm:text-base rounded ${
              disabled ? "bg-black text-gray-600" : "bg-second"
            }`}
            disabled={disabled}
            onClick={fetchMorePosts}
          >
            Load More
          </button>
        </div>
      </>
    </div>
  );
};

export default AllCompletedPosts;
