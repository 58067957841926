import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import LoadingSpinner from "../../../components/CommonComponents/LoadingSpinner/LoadingSpinner";
import ConfigCard from "../../../components/ConfigComponents/ConfigCard/ConfigCard";
import AddNewConfigCard from "../../../components/ConfigComponents/AddNewConfigCard/AddNewConfigCard";
import useConfig from "../../../hooks/useConfig";
import ConfigModal from "../../../components/ConfigComponents/ConfigModal/ConfigModal";

const Config = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState();
  const { configs, setConfigs } = useConfig();
  const { allConfigParams, setAllConfigParams, contentTypes, setContentTypes } = useConfig();
  const [vpsAccounts, setVpsAccounts] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const maintenace = false;

  const getAllParams = () => {
    setLoading(true);
    axios
      .get("api/v1/config/param?index=0&limit=20", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setAllConfigParams(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAllConfigs = () => {
    setLoading(true);
    axios
      .get("api/v1/config/content?index=0&limit=20", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setConfigs(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAllVps = () => {
    setLoading(true);
    axios
      .get("api/v1/role/vps", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setVpsAccounts(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };


  const getAllContentType = () => {
    axios.get(('/api/v1/content-type'), {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    })
    .then((response)=> {
      setLoading(false)
      setContentTypes(response.data.data);
    })
    .catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    contentTypes.length < 1 && getAllContentType();
  } ,{})
  useEffect(() => {
    configs.length < 1 && getAllConfigs();
  }, []);

  useEffect(() => {
    allConfigParams.length < 1 && getAllParams();
  }, []);

  useEffect(() => {
    isChanged && getAllConfigs();
  }, [isChanged]);

  useEffect(() => {
    getAllVps();
  }, []);

  return (
    <div className="w-full h-full p-5">
      {loading ? (
        <div className="h-screen">
          <LoadingSpinner loading={true} />
        </div>
      ) : (
        maintenace ? <div>
          This page is not available right now.
          </div> :
        <div className="grid sm:grid-cols-2 lg:grid-cols-3">
          {configs.map((config) => (
            <ConfigCard
              key={config.id}
              id={config.id}
              configParams={config.config_params}
              allParams={allConfigParams}
              contentType={config.content_type.content_type_name}
              createdAt={config.created_at}
              lastUpdated={config.last_updated}
              name={config.name}
              vpsAccounts={vpsAccounts}
              setIsChanged={setIsChanged}
            />
          ))}
          <AddNewConfigCard setIsChanged={setIsChanged} />
        </div>
      )}
    </div>
  );
};

export default Config;
