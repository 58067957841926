import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../CompletedModal/CompletedModal.css";
import CompletedPostCard from "../../HomeComponents/CompletedPostCard/CompletedPostCard";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";


const IpModal = ({ isOpen, onClose, ip, isFingerPrint, fingerPrint }) => {
  const [ posts, setPosts ] = useState([]);
  const { auth } = useAuth();
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if(!isFingerPrint) {
      isOpen && fetchPostsByIp();
    }
    else {
      isOpen && fetchPostsByFingerprint();
    }
  }, [isOpen]);

  const fetchPostsByFingerprint = () => {
    setLoading(true);
    axios
    .get(`/api/v1/complete/fingerprints/?fingerprint=${fingerPrint}`, {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
      withCredentials: true,
    })
    .then((response) => {
      setPosts(response.data.data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });

  }

  const fetchPostsByIp = () => {
    if (ip) {
      setLoading(true);
      axios
        .get(`/api/v1/complete/ips/?ip_address=${ip}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          setPosts(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <CSSTransition in={isOpen} timeout={500} classNames="modal" unmountOnExit>
      <div className="fixed inset-0 bg-transparent flex items-center justify-center">
        <div onClick={onClose} className="w-full h-full absolute z-1">

        </div>
        <div className="bg-main md:ml-[15rem] shadow-2xl shadow-black mt-12 sm:mt-0  rounded h-[80%] w-[90%] sm:w-[70%] md:w-[50%] lg:w-[750px]  overflow-y-scroll">
          <div className="modal-content  relative h-full py-8 px-3">
            {
            loading ? (
              <div className="absolute  left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <LoadingSpinner loading={true} />
              </div>
            ) : (
              <>
              <div onClick={onClose} className="absolute right-14">
                <div className="fixed text-[#FB4149] cursor-pointer ease-in-out hover:duration-300 hover:text-red-700 hover:scale-110 rounded-full">
                <CloseIcon />
                </div>
              </div>
            <h2 className="sm:text-xl text-center text-gray-300 font-bold">
              Completed Posts of  <br className="block" /> {ip ? ip : fingerPrint?.slice(0,15)}
            </h2>
              <div className="grid grid-cols-1 w-full ">
                {posts.map((post) => (
                  <CompletedPostCard
                    key={post.id}
                    content_id={post.content_id}
                    content_type={post.content_type}
                    ip_address={post.ip_address}
                    finger_print={post.finger_print}
                    completed_at={post.completed_at}
                    on_use_at={post.on_use_at}
                    ready_at={post.ready_at}
                    isIp={true}
                  />
                ))}
              </div>
              <div className="flex !w-full justify-end pr-[1.75rem] bottom-0 pb-2">
              <button
                onClick={onClose}
                className="bg-gray-400 hover:bg-black transition duration-200 text-white py-2 mt-3 px-5 rounded"
              >
                Close
              </button>
            </div>
              </>
            )}
          </div>

        </div>
      </div>
    </CSSTransition>
  );
};

export default IpModal;
