import React, { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../api/axios';
import AccountCard from '../../../components/AccountComponents/AccountCard/AccountCard';
import LoadingSpinner from '../../../components/CommonComponents/LoadingSpinner/LoadingSpinner';
import AddAccountCard from '../../../components/AccountComponents/AddAccountCard/AddAccountCard';
import useAccount from '../../../hooks/useAccount';

const YoutubeAccount = () => {
  const { auth } = useAuth();
  const { ytAccounts, setYtAccounts } = useAccount();
  const [loading, setLoading] = useState();
  const [isChanged, setIsChanged] = useState();
  const [isInfoChanged, setIsInfoChanged] = useState();
  const getAllYtAccounts = () => {
    setLoading(true)
    axios
      .get("api/v1/account/youtube", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setYtAccounts(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(()=>{
    ytAccounts?.length < 1 && getAllYtAccounts();
  }, [])

  useEffect(()=>{
    isChanged && getAllYtAccounts();
  }, [isChanged])

  useEffect(()=>{
    isInfoChanged && getAllYtAccounts();
  }, [isInfoChanged])


  return (
    <div className='w-full h-full p-5'>

        {
            loading ? <div className='h-screen'>
                <LoadingSpinner loading={true}/> 
            </div>:
            <div className='grid md:grid-cols-2'>
            {
            ytAccounts?.map((account)=>(
                <AccountCard setIsInfoChanged={setIsInfoChanged} type={account.type} key={account.id} id={account.id} title={account.title} email={account.email} password={account.password} />
            ))
            }
            <AddAccountCard setIsChanged={setIsChanged} type="YOUTUBE"/>
            </div>
        }

    </div>
  )
}

export default YoutubeAccount
