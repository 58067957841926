import React, { useState, useEffect } from "react";
import Sidebar from "../components/CommonComponents/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import LogoutModal from "../components/CommonComponents/LogoutModal/LogoutModal";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/CommonComponents/NavBar/NavBar";
import useLoading from "../hooks/useLoading";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import { BiX } from "react-icons/bi";

const MainLayout = () => {
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { user, setUser, base64Data, setBase64Data } = useUser();
  const { auth } = useAuth();
  const [info, setInfo] = useState();

  const getUserImage = (hashPath) => {
    axios
      .get(`api/v1/user/profile-image/${hashPath}`, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setBase64Data(Buffer.from(response.data, "binary").toString("base64"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.profile_image_path) {
      getUserImage(user?.profile_image_path);
    }
  }, [user]);

  useEffect(() => {
    if (user.role === "USER") {
      setInfo(true);
    }
  }, [user]);

  const handleSignout = async () => {
    setLoading(true);
    await logout();
    setLoading(false);
    setIsSignoutModalOpen(false);
    navigate("/login");
  };

  const toggleNav = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const getUser = async () => {
    axios
      .get("api/v1/user/current", {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="bg-black relative">
      <NavBar
        toggleNav={toggleNav}
        setIsSignoutModalOpen={setIsSignoutModalOpen}
      />
      <Sidebar
        user={user}
        toggleNav={toggleNav}
        isOpen={isMobileOpen}
        setIsOpen={setIsMobileOpen}
        setIsSignoutModalOpen={setIsSignoutModalOpen}
      />
      {info && (
        <div className="fixed top-0 py-3 px-5 text-white font-semibold z-[999] flex items-center w-full justify-between bg-[#FB4149]">
          <p>This is a limited account. Since you have the role USER you are restricted to see all of the contents.</p>
          <BiX onClick={()=>setInfo(false)} className="w-[1.8rem] h-[1.8rem] cursor-pointer duration-300 hover:scale-110 hover:text-gray-400"/>
        </div>
      )}

      <div className="pt-[72px] md:pt-0 md:ml-[240px] flex justify-center min-h-screen text-white relative">
        <Outlet />
      </div>
      <LogoutModal
        loading={loading}
        isOpen={isSignoutModalOpen}
        setIsSignoutModalOpen={setIsSignoutModalOpen}
        onConfirm={handleSignout}
        onCancel={() => setIsSignoutModalOpen(false)}
      />
    </div>
  );
};

export default MainLayout;
