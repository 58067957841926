import React, { useRef, useEffect, useState } from "react";
import {
  BiCheckCircle,
  BiInfoCircle,
  BiLoaderCircle,
  BiXCircle,
} from "react-icons/bi";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../CommonComponents/LoadingSpinner/LoadingSpinner";
import useConfig from "../../../hooks/useConfig";
const AddConfigModal = ({ isOpen, setIsOpen, setIsChanged }) => {
  const [formValue, setFormValue] = useState({
    name: "",
    content_type: "0",
  });
  const [isSuccess, setIsSuccess] = useState();

  const { contentTypes } = useConfig();
  const [loading, setLoading] = useState();
  const { auth } = useAuth();
  const modalRef = useRef(null);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setIsChanged(false);
  }, []);

  useEffect(() => {
    console.log(formValue);
  }, [formValue]);

  const isInputValid = (inputValue, minLength) => {
    return inputValue.length >= minLength;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!isInputValid(formValue.name, 1)) {
      setErrMsg("Please enter a longer name");
      setFormValue({
        ...formValue,
        title: "",
      });
      return;
    } else if (
      formValue.content_type === "0" ||
      formValue.content_type === ""
    ) {
      setErrMsg("Please select a content type");
      setFormValue({
        ...formValue,
        content_type: "0",
      });
      return;
    }
    setLoading(true);
    setFormValue({
      name: "",
      content_type: "",
    });
    axios
      .post(
        "/api/v1/config/content",
        {
          name: formValue.name,
          content_type_id: parseInt(formValue.content_type),
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setIsSuccess(true);
        setTimeout(() => {
          setLoading(false);
          setIsChanged(true);
        }, 3000);

      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setTimeout(() => {
          setLoading(false);
          setIsChanged(true);
        }, 3000);
      });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(modalRef);
  return (
    <>
      <div
        className={`bg-green-200 scale-0 absolute text-sm sm:text-base left-[20%] sm:left-[30%] lg:left-[40%] top-[7rem] z-[99] sm:top-5 text-green-700 border-l-4 rounded rounded-l-none border-green-700 p-4 flex items-center duration-300 justify-center gap-8 ${
          isSuccess === true && "scale-100"
        }`}
      >
        <BiCheckCircle className="w-[2rem] h-[2rem]" />
        <p>Process completed.</p>
      </div>
      <div
        className={`bg-red-200 scale-0 absolute text-sm sm:text-base left-[20%] sm:left-[30%] lg:left-[40%] top-[7rem] z-[99] sm:top-5 text-red-700 border-l-4 rounded rounded-l-none border-red-700 p-4 flex items-center duration-300 justify-center gap-8 ${
          isSuccess === false && "scale-100"
        }`}
      >
        <BiXCircle className="w-[2rem] h-[2rem]" />
        <p>Process failed.</p>
      </div>
      <div
        ref={modalRef}
        className={`${
          isOpen ? "absolute animate-fadeIn0" : "hidden animate-fadeOut"
        } duration-300 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 max-w-screen max-h-screen bg-main shadow-3xl rounded flex p-5 justify-start flex-col gap-5 z-[99]`}
      >
        <BiXCircle
          onClick={() => setIsOpen(false)}
          className="absolute right-5 top-[1.4rem] w-[1.5rem] h-[1.5rem] cursor-pointer text-[#FB4149] hover:text-red-600 duration-200 hover:scale-110"
        />

        <h2 className="text-base sm:text-lg ">Add New Config</h2>
        <div
          className={`flex text-[#FFAB00] flex-row gap-3 items-center transform transition-all ease-in-out duration-300 ${
            errMsg
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-[-2rem] h-0 overflow-hidden"
          }`}
        >
          <BiInfoCircle className="w-[1.5rem] h-[1.5rem]" />
          {errMsg}
        </div>
        <div className="flex flex-col gap-2 sm:flex-row sm:gap-24 items-start text-sm sm:text-base sm:items-center ">
          <label className="justify-start">Name </label>
          <input
            name="name"
            className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64 focus:ring focus:ring-second transition focus:outline-none"
            placeholder="Name"
            value={formValue.name}
            onChange={handleInputChange}
            required
            disabled={loading ? true : false}
          />
        </div>
        <div className="flex flex-col gap-2 sm:flex-row sm:gap-10 items-start text-sm sm:text-base sm:items-center ">
          <label htmlFor="content_type" className="justify-start">
            Content Type{" "}
          </label>
          <select
            id="content_type"
            name="content_type"
            className="bg-[#2A3038] px-3 py-2 rounded text-sm text-white w-64  focus:ring focus:ring-second focus:outline-none"
            value={formValue.content_type}
            onChange={handleInputChange}
            required
            disabled={loading ? true : false}
          >
            <option value="0">Set Content Type</option>
            {contentTypes.map((type) => (
              <option key={type.content_type_id} value={type.content_type_id}>
                {type.content_type_name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end w-full mt-4 text-sm sm:text-base">
          <button
            onClick={handleSave}
            type="submit"
            className="bg-[#0090E6] hover:bg-blue-400 w-[5rem] h-8 flex items-center justify-center duration-200 px-3 py-1 rounded"
            disabled={loading ? true : false}
          >
            {loading ? (
              <BiLoaderCircle className="w-[1.5rem] h-[1.5rem] animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddConfigModal;
