import React, { useState } from 'react';
import { useTransition } from 'react-spring';
import './AddPost.css'; // Import a separate CSS file for styling
import { fetchNewRedditContents } from './GetPosts.js';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import config from '../../config';
import PostDetailModal from '../../components/AddPostComponents/postDetailModal/PostDetailModal';
import ProgressBar from '../../components/AddPostComponents/ProgressBar/ProgressBar';
import LoadingSpinner from '../../components/CommonComponents/LoadingSpinner/LoadingSpinner';
import PostCard from '../../components/AddPostComponents/PostCard/PostCard';

const AddPost = () => {
  const [loading, setLoading] = useState(false);
  const [showPosts, setShowPosts] = useState(false);
  const [posts, setPosts] = useState([]);
  const [percentage, setPercentage] = useState(75);
  const [notExistPosts, setNotExistPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [existAmount, setExistAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  const [fetchingSubreddit, setFetchingSubreddit] = useState('');

  const { auth } = useAuth();

  const fetchData = async () => {
    setShowPosts(false);
    setLoading(true);
    let temp_posts = await fetchNewRedditContents(setFetchingSubreddit);
    temp_posts = temp_posts.filter(post => typeof post !== 'object' || !(post instanceof Promise));
    const filteredPosts = temp_posts.filter(post => {

      // Time validation
      if (post.created_utc === null) {
        post.created_utc = new Date(1609459200);
      }

      // Author validation
      if (post.author === null) {
        post.author = "anonymous";
      }

      // Text validation
      if (
        post.text !== undefined &&  // Check if post.text is defined
        post.text !== null &&       // Check if post.text is not null
        typeof post.text === 'string' &&  // Check if post.text is a string
        post.text !== "" &&         // Check if post.text is not an empty string
        post.text !== "[removed]" && // Check if post.text is not "[removed]"
        post.text !== "[deleted]"   // Check if post.text is not "[deleted]"
      ) {
        post.text = post.text.replace(/\[.*?\]\(.*?\)/g, '');
      }

      return (
        typeof post === 'object' &&
        post !== null &&
        post.constructor === Object && // Check if it's a plain object
        post.title !== undefined && // Check if it has a title property (or any other property that identifies your posts)
        //len(text.split(" ")) > 23 and len(text.split(" ")) < 180 and len(text) > 300 and len(text) < 700
        post.text.toString().length > 300 &&
        post.text.toString().length < 700 &&
        post.text.toString().split(' ').length > 23 &&
        post.text.toString().split(' ').length < 180
      );
    });

    setPosts(filteredPosts);

    let tempNotExistPosts = [];
    Promise.all(filteredPosts.map(async (post) => {
      let exist = await checkPostExist(post.post_id);
      if (!exist.data) {
        // posts.splice(i, 1);
        tempNotExistPosts.push(post);
      }
    }));
    // setPosts(posts);
    setNotExistPosts(tempNotExistPosts);

    //wait 1 second
    await new Promise(r => setTimeout(r, 1000));
    await getContentAmount(tempNotExistPosts.length);

    setLoading(false);
    setShowPosts(true);
  };

  const removePost = (id) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.post_id !== id));
  };

  const addPost = async (id) => {
    const post = posts.find((post) => post.post_id === id);

    try {
      await axios.post(config.baseUrl + `api/v1/reddit/`, {
        title: post.title,
        text: post.text,
        author: post.author,
        post_id: post.post_id,
        subreddit: post.subreddit,
        ups: post.ups,
        downs: post.downs
      }, {
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
        }
      });

      setPercentage((prevPercentage) => prevPercentage + (100 / notExistPosts.length));
      setExistAmount((prevAmount) => prevAmount + 1);
      setPosts((prevPosts) => prevPosts.filter((post) => post.post_id !== id));
      setNotExistPosts((prevPosts) => prevPosts.filter((post) => post.post_id !== id));
    } catch (error) {
      console.error(error);
    }
  }

  const transitions = useTransition(posts, {
    keys: (post) => post.post_id,
    from: { transform: 'translateX(-400%)', opacity: 0 },
    enter: { transform: 'translateX(0%)', opacity: 1 , config: { duration: 2000 }},
    leave: { transform: 'translateX(100%)', opacity: 0, config: { duration: 500 } },
    
  });

  const checkPostExist = async (id) => {
    try {
      const response = await axios.get(config.baseUrl + `api/v1/reddit/post/${id}/exist`, {
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const ifExistBgColor = (id) => {
    const exist = notExistPosts.find((post) => post.post_id === id);
    return exist ? '#6c937f ' : '#937f6c'; // Green if it doesn't exist, red if it exists
  };

  const openModal = (post) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const getContentAmount = async (amount) => {
    try {
      const response = await axios.get(config.baseUrl + `api/v1/reddit/count`, {
        headers: {
          authorization: `Bearer ${auth.accessToken}`,
        }
      });
      setPercentage(0);
      setExistAmount(response.data.data);
      setFinalAmount(response.data.data + amount);
    } catch (error) {
      console.error(error);
    }
  }

  const addAllNotExistPosts = async () => {
    try {
      notExistPosts.map(async (post) => {
        addPost(post.post_id);
      });

    } catch (error) {
      console.error(error);
    }
  }

  const removeAllExistPosts = async () => {
    try {
      posts.map(async (post) => {
        let exist = await checkPostExist(post.post_id);
        if (exist?.data) {
          removePost(post.post_id);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className=" mx-auto flex items-center ">
      {loading ? (
        <LoadingSpinner loading={loading} fetchingSubreddit={fetchingSubreddit} />
        ) : (<div>
        <h1 className="text-xl">Fetch Post</h1>
        <div className="scroll-container max-w-[700px]  bg-main rounded-lg ">
          {showPosts &&
            transitions((props, item, key) => (
              <PostCard
                props={props}
                key={key}
                post={item}
                removePost={removePost}
                addPost={addPost}
                ifExistBgColor={ifExistBgColor}
                openModal={openModal}
              />
            ))}
          {
            showPosts && posts.length === 0 && (
              <div className="flex flex-col justify-center items-center h-[400px]">
                <p className="text-2xl">No Post</p>
                <button onClick={fetchData} className="bg-second rounded-xl text-white px-4 py-2 mt-4 ml-4">
                  Fetch Post
                </button>
              </div>
            )
          }
        </div>
        {showPosts && (
          <ProgressBar
            percentage={percentage}
            existAmount={existAmount}
            finalAmount={finalAmount}
          />
          )}

{!showPosts ? (
            <button
              onClick={fetchData}
              className="bg-second rounded-xl text-white px-4 py-2 mt-4 "
            >
              Verileri Getir
            </button>
          ) : (
            <div className="mt-4  flex ">
              <button
                onClick={addAllNotExistPosts}
                className="bg-[#0000ff] rounded-xl text-white px-4 py-2 mt-4 ml-4"
              >
                Add Not Exist Posts
              </button>
              <button
                onClick={removeAllExistPosts}
                className="bg-orange-500 rounded-xl text-white px-4 py-2 mt-4 ml-4"
              >
                Remove Exist Posts
              </button>
            </div>
          )}
          {selectedPost && (
            <PostDetailModal post={selectedPost} closeModal={closeModal} />
          )}
        </div>
      )}
    </div>
  );
};

export default AddPost;
