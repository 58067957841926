import React, {useState} from "react";
import { BiCog } from "react-icons/bi";
import AddNewConfigCard from "../AddNewConfigCard/AddNewConfigCard";
import ConfigModal from "../ConfigModal/ConfigModal";

const ConfigCard = ({
  id,
  configParams,
  allParams,
  contentType,
  createdAt,
  lastUpdated,
  name,
  vpsAccounts,
  setIsChanged
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
            onClick={() => {
              setIsOpen(true);
            }}
        className={`hover:scale-105 transition cursor-pointer bg-main p-4 md:p-5 rounded m-4 flex flex-col justify-center animate-fadeIn0 duration-300`}
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <h2 className="text-base sm:text-xl">{name}</h2>
            <p className="text-second text-xs sm:text-sm">
              {configParams?.length} config params
            </p>
          </div>
          <div className="flex items-center justify-center">
            <BiCog className="w-[2rem] h-[2rem] sm:w-[4rem] sm:h-[4rem]" color="#0C6DFD" />
          </div>
        </div>
      </div>
      <ConfigModal setIsChanged={setIsChanged} vpsAccounts={vpsAccounts} allParams={allParams} isOpen={isOpen} setIsOpen={setIsOpen} id={id} configParams={configParams} contentType={contentType} createdAt={createdAt} lastUpdated={lastUpdated} name={name} />
    </>
  );
};

export default ConfigCard;
