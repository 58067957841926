import React from "react";
import { BiLogoReddit } from "react-icons/bi";

const HomeToday = ({
  id,
  bg,
  contentId,
  ipAddress,
  completedTime,
  fingerPrint,
}) => {
  const calcTime = (completedTime) => {
    const now = new Date();
    const completedDate = new Date(completedTime);
    const timeDifference = now - completedDate;
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60); 
    const hoursAgo = Math.floor(minutesAgo / 60); 
    const daysAgo = Math.floor(hoursAgo / 24); 
  
    if (secondsAgo < 60) {
      return `${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`;
    } else if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
    } else {
      return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
    }
  };
  return (
    <div className="flex flex-row justify-between py-3  border-b-[1px] border-b-[#2B2E33]">
      <div className="flex justify-center items-center gap-4 text-base">
        <div className={`bg-[${bg}] p-2 flex items-center justify-center`}>
          <BiLogoReddit className="w-[1.2rem] h-[1.2rem] sm:w-[2rem] sm:h-[2rem]" />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs sm:text-[16px]">Content Id: {contentId}</p>
          <p className="text-xs sm:text-[14px] text-second">{ipAddress}</p>
        </div>
      </div>

      <div className="flex flex-col justify-center text-xs gap-3 sm:text-[14px] text-second">
        <p>{calcTime(completedTime)}</p>
        <p>{fingerPrint.slice(0, 15)}</p>
      </div>
    </div>
  );
};

export default HomeToday;
