import React from "react";
import styles from "./style.module.css";


const PostDetailForm = ({isReadOnly, title, author, onUse, completed, createdAt, postId, handleComplete, handleRestore, handleUpdate,text, setTitle, setAuthor, setOnUse, setCompleted, setCreatedAt, setPostId, setText}) => {

    return (
        <>
         <div className={`card ${styles.card} rounded-lg shadow p-6 h-full w-full max-w-xl bg-main text-sm sm:text-base`}>
                <h4 className="text-xl sm:text-2xl font-semibold mb-4 text-white">Post Details</h4>
                <form className="space-y-4">
                    <div className="grid md:grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label htmlFor="title" className="mb-1 text-white">Title</label>
                            <input 
                                readOnly = {isReadOnly}
                                placeholder="Title"
                                type="text"
                                id="title"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={title || ""}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        
                        <div className="flex flex-col">
                            <label htmlFor="author" className="mb-1 text-white">Author</label>
                            <input 
                                readOnly = {isReadOnly}
                                placeholder="Author"
                                type="text"
                                id="author"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={author || ""}
                                onChange={(e) => setAuthor(e.target.value)}
                                
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="on_use" className="mb-1 text-white">On Use</label>
                            <input
                                readOnly = {isReadOnly}
                                placeholder="On Use"
                                type="text"
                                id="on_use"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={onUse || false}
                                onChange={(e) => setOnUse(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="completed" className="mb-1 text-white">Completed</label>
                            <input
                                readOnly = {isReadOnly}
                                placeholder="False"
                                type="text"
                                id="completed"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={completed || ""}
                                onChange={(e) => setCompleted(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="created_at" className="mb-1 text-white">Created At</label>
                            <input
                                readOnly = {isReadOnly}
                                type="datetime-local"
                                id="created_at"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={createdAt || ""}
                                onChange={(e) => {setCreatedAt(e.target.value); console.log(createdAt);}}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="post_id" className="mb-1 text-white">Post Id</label>
                            <input 
                                readOnly = {isReadOnly}
                                placeholder="Post Id"
                                type="text"
                                id="post_id"
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                value={postId || ""}
                                onChange={(e) => setPostId(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col">
                            <label htmlFor="text" className="mb-1 text-white">Text</label>
                            <textarea
                                className="py-2 px-3 border rounded-lg bg-gray-200 text-gray-800"
                                readOnly = {isReadOnly}
                                id="text"
                                rows="4"
                                value={text || ""}
                                onChange={(e) => setText(e.target.value)}
                            ></textarea>
                        </div>

                    <div className="flex justify-between items-center">
                       { handleComplete && <button className="bg-green-500 text-white py-2 px-4 rounded-lg" type="button" onClick={handleComplete}>Complete</button>}
                       { handleRestore && <button  className="bg-second text-white py-2 px-4 rounded-lg" type="button" onClick={handleRestore}>Restore</button>}
                       { handleUpdate && <button  className="bg-blue-500 text-white py-2 px-4 rounded-lg" type="button" onClick={handleUpdate}>Update</button>}
                    </div>
                </form>
            </div></>
    );
}

export default PostDetailForm;